import StudentUnion from "../components/SUG/StudentUnion";

const routes = [
  {
    path: "/student-union",
    name: "studentUnion",
    component: StudentUnion,
    meta: { requiresVisitor: true },
  },
];

export default routes;
