const identityStatuses = ["ACTIVE", "BLOCKED", "INVALID", "FLAGGED"];
const bloodGroups = ["A+", "B+", "AB+", "O+", "A-", "B-", "AB-", "O-"];

function statusColor(status) {
  const STATUS = status.toUpperCase();

  switch (STATUS) {
    case identityStatuses[0]:
      return "green";
    case identityStatuses[1]:
      return "red";
    case identityStatuses[2]:
      return "brown";
    case identityStatuses[3]:
      return "orange";
    default:
      return "";
  }
}

export { identityStatuses, bloodGroups, statusColor };
