<template>
  <div>
    <v-card class="mb-10">
      <v-card-title>
        <span style="word-break: break-word" :class="$vuetify.breakpoint.smAndDown ? 'mb-2' : ''">
          {{ profile.person.name }} ({{ profile.person.gender }})
          <span class="grey--text identification">
            &mdash; {{ profile.person.matric }}
          </span>
        </span>
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
        <v-chip label small class="mr-2 white--text text-uppercase" :color="statusColor(profile.status)">
          {{ profile.status }}
        </v-chip>
        <v-btn depressed small @click="showDetails = !showDetails">
          {{ showDetails ? "Hide" : "Show" }} Details
          <v-icon right>
            {{ showDetails ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-expand-transition>
        <div class="grey lighten-4" v-if="showDetails">
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col class="images" cols="12" lg="5">
                <div>
                  <img ref="passport" :class="admin ? 'smaller' : ''" class="passport" :src="`${profile.person.passport
                      ? `https://uniuyo.edu.ng/eportals/passports/${profile.person.passport}`
                      : '/favicon.png'
                    }`" alt="Passport" />
                  <p class="text-center pa-0 ma-0 mt-1">
                    <v-btn text color="brown" :to="`/verify/${profile.identity}`">
                      Information
                    </v-btn>
                  </p>
                </div>

                <div>
                  <qrcode-vue :value="`${baseURL}${profile.identity}`" :size="$vuetify.breakpoint.smAndDown ? 140 : admin ? 140 : 160
                    " level="H"></qrcode-vue>

                  <p class="text-center pa-0 ma-0 mt-1">
                    <v-btn text color="brown" :to="`/verify/${profile.identity}`">
                      Verify
                    </v-btn>
                  </p>
                </div>
              </v-col>
              <v-col class="d-flex" cols="12" lg="7">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>Full name &mdash; (Gender)</td>
                      <td>
                        {{ profile.person.name }} &mdash; ({{
                          profile.person.gender,
                        }})
                      </td>
                    </tr>

                    <tr>
                      <td>Matriculation Number</td>
                      <td>{{ profile.person.matric }}</td>
                    </tr>

                    <tr>
                      <td>Blood Group</td>
                      <td>{{ profile.person.bloodGroup }}</td>
                    </tr>

                    <tr>
                      <td>Department</td>
                      <td>{{ profile.person.department }}</td>
                    </tr>

                    <tr>
                      <td>Faculty</td>
                      <td>{{ profile.person.faculty }}</td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions class="pa-3 card-footer">
            <v-card flat width="200px" color="orange lighten-4" class="pl-1 pr-3 mx-1 my-1">
              <v-form class="ma-0 pa-0" @submit.prevent="updateIdentityStatus">
                <v-select solo dense flat hide-details width="80px" height="16px" class="identification text-uppercase"
                  @click:append-outer="updateIdentityStatus" append-outer-icon="mdi-content-save-check"
                  v-model="cardStatus" :items="cardStatuses" label="Card status" :loading="updatingStatus"
                  :disabled="updatingStatus"></v-select>
              </v-form>
            </v-card>

            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon outlined v-on="on" v-bind="attrs" class="mx-1 my-1" color="teal darken-2"
                  @click="markAsPrinted(profile.identity)" v-if="
                    (!profile.printCount && profile.status != 'blocked') ||
                    admin
                  " :loading="marking" :disabled="marking">
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Mark as printed</span>
            </v-tooltip> -->

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon outlined v-on="on" v-bind="attrs" class="mx-1 my-1" color="teal darken-2" target="_blank"
                  :href="`${appURL}/print/${profile.identity}`" v-if="(!profile.printCount && profile.status != 'blocked') ||
                    admin">
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Download for print</span>
            </v-tooltip>

            <v-tooltip bottom v-if="profile.info_updated">
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="removeStudentInfo" icon outlined v-on="on" v-bind="attrs" class="mx-1 my-1"
                  color="orange darken-2" :loading="removingStudentInfo" :disabled="removingStudentInfo">
                  <v-icon>mdi-account-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Remove student info</span>
            </v-tooltip>

            <!-- // Disable card deletion -->
            <!-- <v-tooltip v-if="false" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-on="on" v-bind="attrs" outlined color="red" class="mx-1 my-1 white--text"
                  @click="deleting = true" v-if="printCount == 0">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete card</span>
            </v-tooltip> -->

            <v-spacer></v-spacer>
            <div v-if="$vuetify.breakpoint.mdAndUp">
              <v-chip small label outlined rounded class="ml-1 mr-2 card-footer-chip">
                Printed: {{ printCount }}
              </v-chip>
              <v-chip small label class="card-footer-chip">
                Created at {{ formatDate(profile.createdAt) }}
              </v-chip>
            </div>
          </v-card-actions>
        </div>
      </v-expand-transition>
    </v-card>

    <DeleteProfile :open="deleting" :identity="profile.identity" @closed="deleting = false" />
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import DeleteProfile from "../../Dialogs/DeleteProfile";
import { formatDate } from "../../../../tools/format";
import { identityStatuses as STATUSES } from "../../../../tools/specs";

export default {
  props: {
    profile: {
      type: Object,
      default: () => { },
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    admin: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    QrcodeVue,
    DeleteProfile,
  },

  data: () => ({
    marking: false,
    deleting: false,
    showDetails: false,
    updatingStatus: false,
    removingStudentInfo: false,
    printCount: 0,
    cardStatus: null,
    cardStatuses: STATUSES,
    baseURL: process.env.VUE_APP_VERIFY_URL,
    appURL: process.env.VUE_APP_URL,
  }),

  watch: {
    expanded(value) {
      this.showDetails = value;
    },
    profile: {
      handler(value) {
        this.cardStatus = String(value.status).toUpperCase();
        this.printCount = value.printCount || 0;
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    userMenu() {
      const { type } = JSON.parse(localStorage.getItem("idmsUser"));
      return this.menu.filter((m) => m.access.indexOf(type) > -1);
    },
  },

  methods: {
    formatDate,

    statusColor(status) {
      const STATUS = status.toUpperCase();

      switch (STATUS) {
        case STATUSES[0]:
          return "green";
        case STATUSES[1]:
          return "red";
        case STATUSES[2]:
          return "brown";
        case STATUSES[3]:
          return "orange";
        default:
          return "";
      }
    },

    redrawPassport() {
      let canvas = document.createElement("canvas");
      canvas.setAttribute("width", 160);
      canvas.setAttribute("height", 160);

      this.$refs.passport.parentNode.insertBefore(canvas, this.$refs.passport);

      let ctx = canvas.getContext("2d");
      let img = this.$refs.passport;

      // // get the scale
      // var scale = Math.min(
      //   canvas.width / img.width,
      //   canvas.height / img.height
      // );
      // // get the top left position of the image
      // var x = canvas.width / 2 - (img.width / 2) * scale;
      // var y = canvas.height / 2 - (img.height / 2) * scale;
      // ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

      var scale = Math.max(
        canvas.width / img.width,
        canvas.height / img.height
      );

      var x = canvas.width / 2 - (img.width / 2) * scale;
      var y = canvas.height / 2 - (img.height / 2) * scale;
      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
    },

    markAsPrinted(id) {
      if (!this.marking) {
        this.marking = true;

        this.$axios
          .put(`/identities/mark?identity=${id}`)
          .then(() => {
            this.$bus.$emit("snackbar", {
              type: "info",
              message: "Identity marked as printed",
            });

            if (this.admin) this.printCount++;
            else this.$bus.$emit("identities.reload");
          })
          .catch(() => {
            this.$bus.$emit("snackbar", {
              type: "error",
              message: "Could not mark identity as printed",
            });
          })
          .finally(() => {
            this.marking = false;
          });
      }
    },

    removeStudentInfo() {
      if (!this.removingStudentInfo) {
        this.removingStudentInfo = true;

        this.$axios
          .delete(`/identities/student-info?identity=${this.profile.identity}`)
          .then(() => {
            this.$bus.$emit("snackbar", {
              type: "info",
              message: "Student info removed.",
            });

            this.$bus.$emit("identities.reload");
          })
          .catch(() => {
            this.$bus.$emit("snackbar", {
              type: "error",
              message: "Could not remove student info.",
            });
          })
          .finally(() => {
            this.removingStudentInfo = false;
          });
      }
    },

    updateIdentityStatus() {
      if (!this.updatingStatus) {
        this.updatingStatus = true;

        this.$axios
          .put(`/identities/status?identity=${this.profile.identity}`, {
            status: this.cardStatus,
          })
          .then(() => {
            this.$bus.$emit("snackbar", {
              type: "info",
              message: "Identity status updated",
            });

            this.$bus.$emit("identities.reload");
          })
          .catch(() => {
            this.$bus.$emit("snackbar", {
              type: "error",
              message: "Could not update identity status",
            });
          })
          .finally(() => {
            this.updatingStatus = false;
          });
      }
    },
  },

  mounted() {
    // this.redrawPassport();
  },
};
</script>

<style lang="scss" scoped>
.profile-card {
  max-width: 960px;
  border: 1px solid #cfcdcd;

  .main {
    display: flex;
    gap: 1.5em;
    width: 100%;
  }

  padding: 1em;
  background: #f4faf6;
  margin-bottom: 1em;

  .details {
    p {
      line-height: 1;
    }

    flex-grow: 1;

    .table {
      font-size: 0.85em;
      background: #ffffff;
    }
  }
}

.table {
  // font-size: 0.85em;
  background: #ffffff;
}

.passport {
  width: 160px;
  height: 160px;
  object-fit: cover;
  object-position: center;
  // display: none;

  &.smaller {
    width: 140px;
    height: 140px;
  }
}

@media screen and (max-width: 720px) {
  .passport {
    width: 130px;
    height: 130px;
  }
}

.images {
  display: flex;
  padding-top: 1.5em;
  align-items: center;
  justify-content: space-evenly;
}

.card-footer-chip {
  margin-top: 3px;
}

.card-footer {
  display: flex;
  flex-wrap: wrap;
}
</style>
