import Login from "../components/Authentication/Login";
import Password from "../components/Authentication/Password";

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { requiresVisitor: true },
  },
  {
    path: "/password",
    name: "password",
    component: Password,
    meta: { requiresVisitor: true },
  },
];

export default routes;
