<template>
  <div>
    <v-card max-width="700" class="my-6 mx-auto" color="basil">
      <v-toolbar color="orange darken-4" class="white--text">
        <v-toolbar-title>
          <div class="d-flex">
            <v-btn small to="/staff">
              <v-icon left>mdi-chevron-left</v-icon>
              Staff
            </v-btn>
            &nbsp;/ Staff details
          </div>
        </v-toolbar-title>
      </v-toolbar>

      <Fetching v-if="loading" />

      <div v-else>
        <div class="py-6 px-7 d-flex align-center">
          <v-chip
            small
            :title="`This staff is ${staff.isActive ? '' : 'not '}active`"
            :color="`${staff.isActive ? 'light-green' : 'grey'} lighten-3`"
          >
            @{{ staff.username }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                v-on="on"
                v-bind="attrs"
                @click="update = !update"
                :color="update ? 'blue darken-1' : 'orange darken-2'"
              >
                <v-icon>mdi-account-edit</v-icon>
              </v-btn>
            </template>
            <span>{{ update ? "Disable" : "Enable" }} update</span>
          </v-tooltip>
        </div>

        <v-divider></v-divider>

        <v-form
          :disabled="!update"
          @submit.prevent="updateStaff"
          class="white py-8 pl-4 pr-5"
          v-model="valid"
        >
          <v-text-field
            prepend-icon="mdi-account-outline"
            label="First name"
            v-model="staff.firstName"
            :rules="rules.required"
            filled
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-account"
            label="Last name"
            v-model="staff.lastName"
            :rules="rules.required"
            filled
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-card-account-details-outline"
            label="Staff number"
            prefix="PF/"
            v-model="staff.staffNumber"
            :rules="rules.required"
            filled
            type="number"
            min="1000"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-account-hard-hat"
            label="Designation"
            v-model="staff.designation"
            :rules="rules.required"
            filled
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-at"
            label="Email"
            v-model="staff.email"
            :rules="rules.email"
            filled
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-phone-outline"
            label="Phone number"
            v-model="staff.phone"
            :rules="rules.phone"
            filled
            v-mask="'NNNN-NNN-NNNN'"
          ></v-text-field>
          <v-select
            prepend-icon="mdi-progress-wrench"
            v-model="staff.type"
            :items="userTypes"
            item-value="value"
            item-text="title"
            filled
            label="Role"
          ></v-select>
          <v-switch
            inset
            class="ml-9 mb-1"
            v-model="staff.isActive"
            label="Active staff"
          ></v-switch>
          <v-text-field
            v-if="update"
            prepend-icon="mdi-lock-outline"
            label="Password"
            v-model="staff.password"
            clearable
            filled
          ></v-text-field>
          <div class="ml-8 mt-4">
            <v-btn
              v-if="update"
              :disabled="!valid || updating"
              :loading="updating"
              type="submit"
              color="green"
              outlined
            >
              Save Update
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-card>
  </div>
</template>

<script>
import Fetching from "../utils/Fetching";
import { required, phone, email } from "../../tools/rules";
export default {
  props: ["username"],

  components: { Fetching },

  data: () => ({
    valid: false,
    updating: false,
    loading: false,
    update: false,

    staff: {
      firstName: null,
      lastName: null,
      staffNumber: null,
      designation: null,
      email: null,
      phone: null,
      password: null,
      isActive: null,
    },
    rules: {
      required,
      phone,
      email,
    },
    userTypes: [
      { title: "Administrator", value: "admin" },
      { title: "Help Desk", value: "desk" },
      { title: "Library", value: "library" },
      { title: "Security", value: "security" },
      { title: "Academic Affairs", value: "academic" },
      { title: "ID Officer", value: "identity" },
    ],
  }),

  methods: {
    updateStaff() {
      const body = {
        ...this.staff,
        ...{ staffNumber: `PF/${this.staff.staffNumber}` },
      };

      this.updating = true;
      this.$axios
        .put(`/staff/${this.username}`, body)
        .then(() => {
          this.fetchStaff();
          this.update = false;
          this.$bus.$emit("snackbar", {
            type: "success",
            message: "Update successful",
          });
        })
        .catch(() => {
          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Update failed",
          });
        })
        .finally(() => {
          this.updating = false;
        });
    },
    fetchStaff() {
      this.loading = true;

      this.$axios
        .get(`/staff/${this.username}`)
        .then(async ({ data: staff }) => {
          const clean = { ...staff };
          clean.staffNumber = clean.staffNumber.split("PF/")[1];
          this.staff = clean;

          // const {
          //   data: { status: pinned },
          // } = await this.$axios.get(`/pinned-status?issue=${issue.id}`);

          // this.pinned = pinned || false;
        })
        .catch(() => {
          this.$router.push({ name: "staff" });
          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Could not load staff details",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.fetchStaff();
  },
};
</script>


<style lang="scss" scoped>
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>