<template>
  <div>
    <h1>Library subscription</h1>
    <v-card flat max-width="420px">
      <v-form
        class="mt-5"
        v-model="finsStudentFormValid"
        @submit.prevent="findStudent"
      >
        <v-text-field
          outlined
          label="Matriculation Number *"
          prepend-inner-icon="mdi-school"
          v-model="registrationNumber"
          hide-details
          clearable
        ></v-text-field>

        <v-btn
          large
          :loading="loading"
          :disabled="loading"
          class="mt-3"
          type="submit"
          color="primary"
          outlined
        >
          Find Student <v-icon right>mdi-account-search-outline</v-icon>
          <template v-slot:loader>
            <span>Finding...</span>
          </template>
        </v-btn>
      </v-form>
    </v-card>

    <div v-if="studentFound.id > 0">
      <v-divider class="mt-7 mb-5"></v-divider>

      <h2>Student details</h2>

      <v-row class="mt-2">
        <v-col sm="12" md="3">
          <v-img
            class="shrink passport-image mx-auto"
            cover
            :src="`${
              studentFound.passport
                ? `https://uniuyo.edu.ng/eportals/passports/${studentFound.passport}`
                : '/favicon.png'
            }`"
            :width="$vuetify.breakpoint.smAndDown ? '230px' : ''"
            transition="scale-transition"
            aspect-ratio="1"
          >
          </v-img>
          <div v-if="studentFound.identity">
            <p class="identity mt-3">
              <v-chip outlined small label color="pink" class="white--text">
                {{ formatIdentity(studentFound.identity) }}
              </v-chip>
            </p>

            <div class="qrcode">
              <qrcode-vue
                :value="`${baseURL}${studentFound.identity}`"
                :size="$vuetify.breakpoint.smAndDown ? 100 : 180"
                level="H"
              ></qrcode-vue>
            </div>

            <p class="text-center">
              <v-btn text :to="`/verify/${studentFound.identity}`">
                Verify
              </v-btn>
            </p>
          </div>
        </v-col>
        <v-col>
          <v-card>
            <v-tabs dark show-arrows color="green lighten-2">
              <v-tab>
                <v-icon left> mdi-account </v-icon>
                Personal
              </v-tab>
              <v-tab>
                <v-icon left> mdi-library </v-icon>
                Library
              </v-tab>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Matriculation Number</td>
                          <td>{{ studentFound.matric }}</td>
                        </tr>
                        <tr>
                          <td>Full Name &mdash; (Gender)</td>
                          <td>
                            {{ studentFound.name }} &mdash; ({{
                              studentFound.gender
                            }})
                          </td>
                        </tr>
                        <!-- <tr>
                          <td>Blood Group</td>
                          <td>{{ studentFound.bloodGroup }}</td>
                        </tr> -->
                        <tr>
                          <td>Course of Study</td>
                          <td>{{ studentFound.course }}</td>
                        </tr>
                        <tr>
                          <td>Department</td>
                          <td>{{ studentFound.department }}</td>
                        </tr>
                        <tr>
                          <td>Faculty</td>
                          <td>{{ studentFound.faculty }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <ul class="listing" v-if="librarySubscriptions.length > 0">
                      <li
                        v-for="(lib, index) in librarySubscriptions"
                        :key="index"
                      >
                        Registered for
                        <strong>{{ lib.sessionIssued }}</strong> on
                        {{ formatDate(lib.created_at) }}
                      </li>
                    </ul>
                    <p v-else class="my-5 overline red--text">
                      No library registration found!
                    </p>
                    <v-divider class="my-5"></v-divider>
                    <IssueLibrarySubscription
                      v-if="studentFound.identity"
                      :uid="studentFound.identity"
                      @issued="findStudent"
                    />
                    <v-card v-else color="red">
                      <v-card-text class="text-center">
                        <h1 class="my-5">No Valid ID</h1>
                        <p class="white--text">
                          This person does not have a valid ID card. Please
                          direct them to get one at the Directorate of ICT.
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { required } from "../../tools/rules";
import { formatIdentity, formatDate } from "../../tools/format";
import IssueLibrarySubscription from "./IssueSubscription.vue";

export default {
  components: {
    QrcodeVue,
    IssueLibrarySubscription,
  },

  data: () => ({
    rules: {
      required,
    },
    loading: false,
    studentFound: {
      id: 0,
      matric: null,
    },
    registrationNumber: null,
    finsStudentFormValid: false,

    tab: null,

    creating: false,
    librarySubscriptions: [],

    baseURL: process.env.VUE_APP_VERIFY_URL,
  }),

  watch: {
    registrationNumber(val) {
      if (val) this.registrationNumber = val.toUpperCase();
    },
  },

  methods: {
    formatDate,
    formatIdentity,
    findStudent() {
      if (!this.registrationNumber) return;

      this.loading = true;
      this.$axios
        .get("/student", { params: { registration: this.registrationNumber } })
        .then(({ data: response }) => {
          this.studentFound = response;
          const { subscriptions } = response;

          this.librarySubscriptions = [...subscriptions].filter(
            (s) => (s.type = "library")
          );
        })
        .catch((error) => {
          console.log(error);

          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Student not found",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
