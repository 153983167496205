<template>
  <div>
    <h2 class="mb-5">
      Student Identity Print Folder ({{ count }} pending cards)
    </h2>
    <div v-if="profiles.length > 0">
      <ProfileCard
        v-for="profile in profiles"
        :key="profile.id"
        :profile="profile"
      />
      <div class="my-5">
        <v-btn
          text
          block
          large
          color="green"
          @click.prevent="loadMoreCards"
          :disabled="this.profiles.length == count || reloading"
        >
          {{ this.profiles.length == count ? "All done!" : "Load more" }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileCard from "./Includes/ProfileCard";

export default {
  components: { ProfileCard },

  data: () => ({
    count: 0,
    profiles: [],

    loading: false,
    reloading: false,
  }),

  methods: {
    fetchCardsNotPrinted(loadMore = false) {
      this.loading = !loadMore;
      this.reloading = loadMore;

      this.$axios
        .get(`/identities/not-printed?skip=${this.profiles.length}`)
        .then(async ({ data: response }) => {
          const { identities, count } = response;
          this.profiles = [...this.profiles, ...identities];
          this.count = count;
        })
        .finally(() => {
          this.loading = false;
          this.reloading = false;
        });
    },
    loadMoreCards() {
      this.fetchCardsNotPrinted(true);
    },
  },

  mounted() {
    this.fetchCardsNotPrinted();

    this.$bus.$on("identities.reload", () => {
      this.profiles = [];
      this.fetchCardsNotPrinted();
    });
  },
};
</script>

<style>
</style>