<template>
  <div>
    <h2>Search for student</h2>
    <v-card flat>
      <v-form
        class="mt-5"
        ref="fetchStudentsForm"
        v-model="fetchStudentsFormValid"
        @submit.prevent="queryStudents"
      >
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              clearable
              v-model="name"
              label="Student name or matric number"
              placeholder="You may enter part of a name or matric number"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-expansion-panels focusable>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Additional filters
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="py-4" dense>
                <v-col lg="3" sm="6" cols="12">
                  <v-select
                    outlined
                    label="Programme"
                    v-model="programme"
                    hide-details
                    clearable
                    multiple
                    :items="programmes"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
                <v-col lg="3" sm="6" cols="12">
                  <v-autocomplete
                    outlined
                    label="Faculty"
                    v-model="faculty"
                    hide-details
                    clearable
                    :items="faculties"
                    item-text="name"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
                <v-col lg="3" sm="6" cols="12">
                  <v-autocomplete
                    outlined
                    label="Department"
                    v-model="department"
                    hide-details
                    clearable
                    :items="filteredDepartments"
                    item-text="name"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
                <v-col lg="3" sm="6" cols="12">
                  <v-autocomplete
                    outlined
                    label="Course"
                    v-model="course"
                    hide-details
                    clearable
                    :items="filteredCourses"
                    item-text="name"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
                <v-col lg="3" sm="6" cols="12">
                  <v-select
                    outlined
                    label="Gender"
                    v-model="gender"
                    hide-details
                    clearable
                    :items="[
                      { label: 'Male', value: 'M' },
                      { label: 'Female', value: 'F' },
                    ]"
                    item-text="label"
                    item-value="value"
                  ></v-select>
                </v-col>
                <v-col lg="3" sm="6" cols="12">
                  <v-select
                    outlined
                    label="Level"
                    v-model="level"
                    hide-details
                    clearable
                    :items="levels"
                    item-text="label"
                    item-value="value"
                  ></v-select>
                </v-col>
                <v-col lg="3" sm="6" cols="12">
                  <v-select
                    outlined
                    label="State"
                    v-model="state"
                    hide-details
                    clearable
                    :items="states"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
                <v-col lg="3" sm="6" cols="12">
                  <v-select
                    outlined
                    label="LGAs"
                    v-model="lga"
                    hide-details
                    clearable
                    :items="filteredLGAs"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div class="mt-3">
          <v-btn
            large
            outlined
            :loading="searching"
            :disabled="searching || !fetchStudentsFormValid || !name"
            class="mr-2 my-1"
            type="submit"
            color="primary"
          >
            Search <v-icon right>mdi-magnify</v-icon>
            <template v-slot:loader>
              <span>Loading...</span>
            </template>
          </v-btn>
          <v-btn
            large
            outlined
            color="red accent-2"
            class="my-1 white--text"
            @click="clearFilters"
          >
            Clear Filters <v-icon right>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-form>
    </v-card>

    <div v-if="students.length > 0" class="my-5">
      <v-divider class="my-7"></v-divider>

      <div class="d-flex mb-2">
        <h2>
          {{ meta.total }}
          <span class="grey--text">
            {{ meta.total > 1 ? "students" : "student" }} found
          </span>
        </h2>
      </div>
      <v-data-table
        multi-sort
        :headers="headers"
        :items="students"
        class="elevation-1"
        hide-default-footer
        loading-text="Loading... Please wait"
        :footer-props="tableFooterProps"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-row align="center">
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="3">
              <div class="voter-image">
                <v-img
                  class="shrink rounded my-1"
                  cover
                  :src="`${
                    item.passport
                      ? `https://uniuyo.edu.ng/eportals/passports/${item.passport}`
                      : '/favicon.png'
                  }`"
                  rounded
                  transition="scale-transition"
                  width="42"
                  height="42"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-3"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
            </v-col>
            <v-col>
              {{ item.name }}
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <v-btn @click="downloadSIF(item.matric)" small text color="green">
            SIF
            <v-icon small class="ml-1"> mdi-download-outline </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <div class="text-right pt-5">
        <v-pagination
          circle
          color="green darken-2"
          total-visible="7"
          @input="fetchStudents"
          v-model="meta.current_page"
          :length="meta.last_page"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "../../../tools/rules";
import { formatIdentity, formatDate } from "../../../tools/format";

export default {
  data: () => ({
    page: 1,
    pageCount: 0,
    students: [],
    searching: false,
    creating: false,
    headers: [
      {
        text: "Student",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Gender", value: "gender" },
      { text: "Reg. Number", value: "matric" },
      { text: "Faculty", value: "faculty" },
      { text: "Department", value: "department" },
      { text: "Download", sortable: false, align: "center", value: "id" },
    ],
    tableFooterProps: {
      itemsPerPageOptions: [-1],
    },
    meta: {
      total: 0,
      last_page: 0,
      current_page: 0,
    },

    query: null,

    required,
    fetchStudentsFormValid: false,

    levels: [
      { label: "100", value: 1 },
      { label: "200", value: 2 },
      { label: "300", value: 3 },
      { label: "400", value: 4 },
      { label: "500", value: 5 },
      { label: "600", value: 6 },
      { label: "700", value: 7 },
      { label: "800", value: 8 },
      { label: "900", value: 9 },
      { label: "1000", value: 10 },
      { label: "1100", value: 11 },
      { label: "1200", value: 12 },
      { label: "1300", value: 13 },
      { label: "1400", value: 14 },
      { label: "1500", value: 15 },
      { label: "1600", value: 16 },
      { label: "1700", value: 17 },
      { label: "1800", value: 18 },
      { label: "1900", value: 19 },
      { label: "2000", value: 20 },
    ],

    courses: [],
    faculties: [],
    programmes: [],
    departments: [],
    sessions: [],
    lgas: [],
    states: [],

    name: null,
    gender: null,
    level: null,
    course: null,
    session: null,
    faculty: null,
    programme: null,
    department: null,
    lga: null,
    state: null,

    // baseURL: process.env.VUE_APP_VERIFY_URL,
  }),

  computed: {
    filteredDepartments() {
      if (this.faculty) {
        return [...this.departments].filter((d) => d.faculty == this.faculty);
      } else return [...this.departments];
    },
    filteredCourses() {
      if (this.faculty && this.department) {
        return [...this.courses].filter(
          (c) => c.faculty == this.faculty && c.department == this.department
        );
      } else if (this.department) {
        return [...this.courses].filter((c) => c.department == this.department);
      } else return [...this.courses];
    },
    filteredLGAs() {
      if (this.state) {
        return [...this.lgas].filter((l) => l.state == this.state);
      } else return [...this.lgas];
    },
  },

  methods: {
    formatDate,
    formatIdentity,

    clearFilters() {
      this.searching = false;
      this.level = null;
      this.course = null;
      this.faculty = null;
      this.programme = null;
      this.department = null;
      this.state = null;
      this.lga = null;
      this.students = [];
      this.$refs.fetchStudentsForm.reset();
    },

    composeQuery(page) {
      const params = new URLSearchParams();

      params.append("name", this.name);

      this.page && params.append("page", page);
      this.gender && params.append("gender", this.gender);
      this.level && params.append("level", this.level);
      this.programme && params.append("programme", this.programme);
      this.faculty && params.append("faculty", this.faculty);
      this.department && params.append("department", this.department);
      this.this && params.append("this", this.this);
      this.state && params.append("state", this.state);
      this.lga && params.append("lga", this.lga);

      return String(params);
    },

    queryStudents() {
      this.fetchStudents(1);
    },

    fetchStudents(page) {
      if (this.name) {
        let query = this.composeQuery(page);
        this.query = query;
        this.searching = true;

        this.$axios
          .get(`/search?${query}`)
          .then(async ({ data: { data: students, meta } }) => {
            this.students = students;

            if (students && students.length > 0) {
              this.meta = meta;
            }
          })
          .finally(() => {
            this.searching = false;
          });
      }
    },

    downloadSIF(matric) {
      const a = document.createElement("a");

      a.href = `https://uniuyo.edu.ng/mvc/view/ePrintOut/MpdfPDFViewer.php?PrintOutType=9&RegNo=${matric}&ActionID=1`;
      a.download = "download";

      const clickHandler = () => {
        setTimeout(() => {
          a.removeEventListener("click", clickHandler);
          a.remove();
        }, 150);
      };

      a.addEventListener("click", clickHandler, false);

      a.click();
    },

    fetchLGAs() {
      this.$axios.get("/lgas").then(async ({ data: lgas }) => {
        this.lgas = lgas;
      });
    },

    getLGAName() {
      const lga = this.lgas.find((l) => l.id == this.lga);
      return lga ? `${lga.name} LGA` : "any LGA";
    },

    fetchStates() {
      this.$axios.get("/states").then(async ({ data: states }) => {
        this.states = states;

        // this.states = states.map((s) => {
        //   s.text = formatName(s.name);
        //   return s;
        // });
      });
    },

    fetchProgrammes() {
      this.$axios.get("/programmes").then(({ data: programmes }) => {
        this.programmes = [...programmes].sort((a, b) =>
          a.name.trim() > b.name.trim() ? 1 : -1
        );
      });
    },

    fetchFaculties() {
      this.$axios.get("/faculties").then(({ data: faculties }) => {
        this.faculties = [...faculties].sort((a, b) =>
          a.name.trim() > b.name.trim() ? 1 : -1
        );
      });
    },

    fetchDepartments() {
      this.$axios.get("/departments").then(({ data: departments }) => {
        this.departments = [...departments].sort((a, b) =>
          a.name.trim() > b.name.trim() ? 1 : -1
        );
      });
    },

    fetchCourses() {
      this.$axios.get("/courses").then(({ data: courses }) => {
        this.courses = [...courses].sort((a, b) =>
          a.name.trim() > b.name.trim() ? 1 : -1
        );
      });
    },
  },

  mounted() {
    this.fetchProgrammes();
    this.fetchFaculties();
    this.fetchDepartments();
    this.fetchCourses();
    this.fetchLGAs();
    this.fetchStates();
  },
};
</script>
