<template>
  <div>
    <v-container class="pt-2">
      <div class="mb-5">
        <v-btn
          tile
          depressed
          color="red lighten-5"
          class="red--text mr-1 mb-1"
          :to="item.location"
          v-for="item in userMenu"
          :key="item.location"
        >
          {{ item.title }}
        </v-btn>
      </div>
      <router-view :key="$route.fullPath"></router-view>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    menu: [
      {
        title: "Latest",
        location: "/identity/staff/latest",
        access: ["admin"],
      },
      {
        title: "Profiles",
        location: "/identity/staff/profiles",
        access: ["admin"],
      },
      {
        title: "Print Folder",
        location: "/identity/staff/print",
        access: ["admin", "identity"],
      },
      {
        title: "Create Identity",
        location: "/identity/staff/create-single",
        access: ["admin", "identity"],
      },
      // {
      //   title: "Create Bulk",
      //   location: "/identity/staff/create-bulk",
      //   access: ["admin", "identity"],
      // },
    ],
  }),

  computed: {
    userMenu() {
      const { type } = JSON.parse(localStorage.getItem("idmsUser"));
      return this.menu.filter((m) => m.access.indexOf(type) > -1);
    },
  },
};
</script>

<style>
</style>