<template>
  <div>
    <v-btn
      outlined
      color="pink"
      :loading="issuing"
      :disabled="issuing"
      @click="issueLibrarySubscription"
    >
      Issue library subscription
      <template v-slot:loader>
        <span>Issuing subscription...</span>
      </template>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["uid"],

  data: () => ({
    issuing: false,
  }),

  methods: {
    issueLibrarySubscription() {
      if (!this.uid) return;

      this.issuing = true;
      this.$axios
        .post("/subscription", { uid: this.uid, type: "library" })
        .then(() => {
          this.$emit("issued");
        })
        .catch(() => {
          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Subscription could not be created",
          });
        })
        .finally(() => {
          this.issuing = false;
        });
    },
  },
};
</script>