<template>
  <div>
    <h2 class="mb-5">Latest Student Identities</h2>
    <ProfileCard
      v-for="profile in profiles"
      :key="profile.id"
      :profile="profile"
      :admin="isAdmin"
    />
  </div>
</template>

<script>
import ProfileCard from "./Includes/ProfileCard";

export default {
  components: { ProfileCard },

  data: () => ({
    fetching: false,
    profiles: [],
  }),

  computed: {
    isAdmin() {
      const { type } = JSON.parse(localStorage.getItem("idmsUser"));
      return type.toLowerCase() == "admin";
    },
  },

  methods: {
    fetchLatestProfiles() {
      this.fetching = true;

      this.$axios
        .get("/identities/latest")
        .then(({ data: profiles }) => {
          this.profiles = profiles;
        })
        .catch((error) => {
          console.log(error);

          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Could not load latest profiles",
          });
        })
        .finally(() => {
          this.fetching = false;
        });
    },
  },

  mounted() {
    this.fetchLatestProfiles();
    this.$bus.$on("identities.reload", this.fetchLatestProfiles);
  },
};
</script>

<style>
</style>