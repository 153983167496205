<template>
  <v-expansion-panels accordion focusable>
    <v-expansion-panel :key="index" v-for="(entry, index) in results">
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-row no-gutters>
            <v-fade-transition leave-absolute>
              <v-col v-if="!open" cols="6">
                <span>
                  {{ entry.level }} Level, {{ entry.semester }} Semester
                </span>
              </v-col>
            </v-fade-transition>

            <v-col cols="6">
              <span>
                CGPA: {{ entry.cgpa.toFixed(2) }}
                <small class="grey--text">
                  | GPA: {{ entry.gpa.toFixed(2) }}
                </small>
                <small v-if="entry.failed" class="red--text">
                  | FAILED: {{ entry.failed.length }}
                </small>
              </span></v-col
            >
          </v-row>
        </template>

        <!-- <v-spacer></v-spacer> -->
      </v-expansion-panel-header>
      <v-expansion-panel-content color="lime lighten-5 pt-4 px-0">
        <!-- <div class="d-flex">
          <h1>
            <small> CGPA </small>
            {{ entry.cgpa }}<br />
          </h1>
          <v-spacer></v-spacer>
          <h3 class="mt-2 mb-3">
            <small> GPA: </small>
            <span class="blue--text">
              {{ entry.gpa }}
            </span>
          </h3>
        </div>
        <v-btn
          small
          depressed
          class="white--text"
          color="green accent-4"
          :disabled="downloading"
          @click="downloadPaySlip(entry)"
        >
          Download Result
          <v-icon small right>mdi-download-outline</v-icon>
        </v-btn> -->

        <!-- <table style="font-size: 13px" class="table mt-3">
          <tbody>
            <tr>
              <th>Course Code</th>
              <th v-if="$vuetify.breakpoint.mdAndUp">Course Title</th>
              <th>CH</th>
              <th>Exam</th>
              <th>CA</th>
              <th>Score</th>
              <th>Grade</th>
            </tr>
            <tr v-for="course in entry.results" :key="course.courseCode">
              <td>{{ course.courseCode }}</td>
              <td v-if="$vuetify.breakpoint.mdAndUp" style="text-align: left">
                {{ course.courseTitle }}
              </td>
              <td>{{ course.creditHours }}</td>
              <td>{{ course.examination }}</td>
              <td>{{ course.assessment }}</td>
              <td>{{ course.totalScore }}</td>
              <td>
                <strong>{{ course.grade }}</strong>
              </td>
            </tr>
          </tbody>
        </table> -->

        <div class="d-flex mb-4">
          <h2>{{ entry.level }} <small>LEVEL</small></h2>
          <v-spacer></v-spacer>
          <div class="text-right text-uppercase">
            <h3>
              {{ entry.semester }} semester,
              {{ entry.session }}
            </h3>
          </div>
        </div>

        <div
          style="border-radius: 4px"
          class="red lighten-4 d-flex mb-3 pa-2 px-3"
        >
          <h4 class="red--text text--darken-4">Failed/Repeat Courses:</h4>
          <span v-if="entry.failed">
            <v-chip
              small
              label
              outlined
              class="mx-1"
              v-for="course in entry.failed"
              :key="course"
            >
              <strong>{{ course }}</strong>
            </v-chip>
          </span>
          <strong v-else class="mx-1">NONE</strong>
        </div>

        <v-data-table
          class="transparent"
          :headers="headers"
          :items="entry.results"
          :items-per-page="-1"
          hide-default-footer
          style="font-size: 13px"
        >
          <template v-slot:[`item.grade`]="{ item }">
            <v-chip small dark :color="item.grade == 'F' ? 'red' : ''">
              <strong>{{ item.grade }}</strong>
            </v-chip>
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { formatCurrency } from "../../../../tools/format";

export default {
  props: {
    results: {
      type: Array,
      default: () => [],
    },
    person: {
      type: Object,
      default: () => {},
    },
    identity: {
      type: String,
    },
  },

  data: () => ({
    downloading: false,
    headers: [
      {
        text: "Code",
        value: "courseCode",
      },
      { text: "Course Title", sortable: false, value: "courseTitle" },
      { text: "CH", sortable: false, value: "creditHours" },
      // { text: "CA", sortable: false, value: "assessment" },
      // { text: "Exam", sortable: false, value: "examination" },
      { text: "Score", sortable: false, value: "totalScore" },
      { text: "Grade", sortable: false, value: "grade" },
    ],
  }),

  methods: {
    formatCurrency,
    downloadPaySlip(entry) {
      if (!this.downloading) {
        const { id } = entry;
        this.downloading = true;

        this.$axios({
          url: `/payslip/${this.identity}/${id}`,
          method: "GET",
          responseType: "blob",
        })
          .then((response) => {
            this.download_result = "";
            this.downloading = false;

            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              `${this.person.full_name} ${
                this.person.uu_staff_id
                  ? "(" + this.person.uu_staff_id + ")"
                  : ""
              } - Payslip for ${entry.month}.pdf`
            );
            document.body.appendChild(fileLink);
            fileLink.click();

            this.$bus.$emit("snackbar", {
              type: "success",
              message: "Payslip downloaded",
            });
          })
          .catch(() => {
            // this.download_result = "";
            this.downloading = false;
            this.$bus.$emit("snackbar", {
              type: "error",
              message: "Could not download payslip",
            });
          });
      }
    },
  },
};
</script>

