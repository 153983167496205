import Vue from "vue";
import axios from "axios";

const UNAUTHORIZED = [401, 403];

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.withCredentials = true;

// let source;

// axios.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("idmsToken");
//     if (token) {
//       config.headers["Authorization"] = "Bearer " + token;
//     }

//     if (source) {
//       source.cancel();
//     }

//     const CancelToken = axios.CancelToken;
//     source = CancelToken.source();

//     window.Event.$emit("request.initiated");

//     config.cancelToken = source.token;
//     return config;
//   },
//   (error) => {
//     Promise.reject(error);
//     window.Event.$emit("request.ended");
//   }
// );

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("idmsToken");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    window.Event.$emit("request.initiated");

    return config;
  },
  (error) => {
    Promise.reject(error);
    window.Event.$emit("request.ended");
  }
);

axios.interceptors.response.use(
  (response) => {
    window.Event.$emit("request.ended");
    return response;
  },
  (error) => {
    window.Event.$emit("request.ended");
    if (error.response && error.response.status) {
      if (UNAUTHORIZED.includes(error.response.status)) {
        window.Event.$emit("request.ended");
        localStorage.removeItem("idmsAuth");
        localStorage.removeItem("idmsUser");
        window.Event.$emit("logout");
      }
      return Promise.reject(error);
    }
  }
);

window.axios = Vue.prototype.$axios = axios;
