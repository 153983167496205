<template>
  <div>
    <div v-if="profileFound">
      <v-row class="mt-2">
        <v-col sm="12" md="4">
          <v-img class="shrink passport-image mx-auto" cover :src="`${person.passport
              ? `https://uniuyo.edu.ng/eportals/passports/${person.passport}`
              : '/favicon.png'
            }`" transition="scale-transition" :width="$vuetify.breakpoint.smAndDown ? '230px' : '300px'"
            aspect-ratio="1">
          </v-img>
          <div v-if="identity">
            <p class="identity mt-3">
              <v-chip outlined small label color="pink" class="white--text">
                {{ formatIdentity(identity) }}
              </v-chip>
            </p>

            <div class="qrcode">
              <qrcode-vue :value="`${baseURL}${identity}`" :size="$vuetify.breakpoint.smAndDown ? 130 : 180"
                level="H"></qrcode-vue>
            </div>
          </div>
        </v-col>
        <v-col sm="12" md="8">
          <UpdateInfo v-if="!studentIdentity.info_updated" :identity="identity" :reg_number="person.matric" />
          <v-card>
            <v-card-title>
              UNIUYO Student Information
              <v-spacer></v-spacer>
              <v-chip class="white--text" :color="statusColor(studentIdentity.status)">
                {{ String(studentIdentity.status).toUpperCase() }}
              </v-chip>
            </v-card-title>
            <v-tabs dark show-arrows v-model="tab" center-active color="green lighten-4" background-color="secondary"
              v-if="String(studentIdentity.status).toUpperCase() == 'ACTIVE'">
              <v-tab>
                <v-icon left> mdi-account </v-icon>
                Personal
              </v-tab>
              <v-tab>
                <v-icon left> mdi-library </v-icon>
                Library
              </v-tab>
              <v-tab>
                <v-icon left> mdi-bookshelf </v-icon>
                Courses
              </v-tab>
              <v-tab>
                <v-icon left> mdi-check-decagram-outline </v-icon>
                Results
              </v-tab>
              <v-tab>
                <v-icon left> mdi-ballot </v-icon>
                E-Voting
              </v-tab>
              <!-- <v-tab>
                <v-icon left> mdi-bed </v-icon>
                Accomodation
              </v-tab> -->
              <v-tab v-if="person.matric == '16/ED/HP/501'">
                <v-icon left> mdi-medical-bag </v-icon>
                Medical
              </v-tab>
              <v-tab disabled>
                <v-icon left> mdi-receipt </v-icon>
                Dues
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Matriculation Number</td>
                          <td>{{ person.matric }}</td>
                        </tr>
                        <tr>
                          <td>Full Name &mdash; (Gender)</td>
                          <td>
                            {{ person.name }} &mdash; ({{ person.gender }})
                          </td>
                        </tr>
                        <tr>
                          <td>Blood Group</td>
                          <td>{{ person.bloodGroup }}</td>
                        </tr>
                        <tr>
                          <td>Course of Study</td>
                          <td>{{ person.course }}</td>
                        </tr>
                        <tr>
                          <td>Department</td>
                          <td>{{ person.department }}</td>
                        </tr>
                        <tr>
                          <td>Faculty</td>
                          <td>{{ person.faculty }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <ul class="listing" v-if="librarySubscriptions.length > 0">
                      <li v-for="(lib, index) in librarySubscriptions" :key="index">
                        Registered for
                        <strong>{{ lib.sessionIssued }}</strong> on
                        {{ formatDate(lib.createdAt) }}
                      </li>
                    </ul>
                    <h3 v-else class="my-5 red--text">
                      No library registration found
                    </h3>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <div v-if="lastRegistration.coursesRegistered &&
                      lastRegistration.coursesRegistered['length'] > 0
                      ">
                      <div style="text-align: right">
                        <h3>
                          Registration for
                          {{ lastRegistration.sessionRegistered }}
                          {{ lastRegistration.semesterRegistered }} Semester
                        </h3>
                        <p>
                          Date Registered:
                          {{ lastRegistration.dateRegistered }}
                        </p>
                      </div>
                      <table class="table">
                        <tbody>
                          <tr>
                            <th>Course Code</th>
                            <th>Course Title</th>
                            <th>Credit Hours</th>
                          </tr>
                          <tr v-for="course in lastRegistration.coursesRegistered" :key="course.CourseID">
                            <td>{{ course.CourseCode }}</td>
                            <td style="text-align: left">
                              {{ course.CourseTitle }}
                            </td>
                            <td>{{ course.CH }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h3 class="my-5 red--text" v-else>
                      No registration for this semester!
                    </h3>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-btn depressed color="lime" class="my-3" @click="fetchResults" v-if="!resultsFetched"
                      :loading="fetchingResults" :disabled="fetchingResults">
                      Load results
                      <v-icon right>mdi-cloud-sync</v-icon>
                    </v-btn>
                    <div v-if="resultsFetched">
                      <section v-if="studentResults.length > 0">
                        <h4>
                          <strong class="orange--text text--darken-2">NOTE:
                          </strong>
                          In case of any inaccuracy, please contact your
                          examination officer
                        </h4>
                        <v-divider class="mt-3 mb-4"></v-divider>
                        <StudentResults :identity="identity" :results="studentResults" />
                      </section>
                      <h3 class="my-5" v-else>No results found</h3>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <h3 class="my-5">Coming soon</h3>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item v-if="person.matric == '16/ED/HP/501'">
                <v-card flat>
                  <v-card-text>
                    <!-- <h2 class="my-5">Medical History</h2> -->

                    <v-card class="mb-5">
                      <v-card-title class="light-green lighten-4 py-2">
                        Allergies
                      </v-card-title>
                      <v-card-text class="py-5">
                        <ul>
                          <li class="mb-3">Dust mites</li>
                          <li class="mb-3">Grass and tree pollen</li>
                          <li>Mould</li>
                        </ul>
                      </v-card-text>
                    </v-card>

                    <v-card class="mb-5">
                      <v-card-title class="red lighten-4 py-2">
                        Family History
                      </v-card-title>
                      <v-card-text class="py-5">
                        <ul>
                          <li :key="history" :class="index + 1 < fakeFamilyHistory.length && 'mb-3'
                            " v-for="(history, index) in fakeFamilyHistory">
                            {{ history }}
                          </li>
                        </ul>
                      </v-card-text>
                    </v-card>

                    <v-card class="mb-5">
                      <v-card-title class="purple lighten-4 py-2">
                        Past Medical History
                      </v-card-title>
                      <v-card-text class="py-5">
                        <v-list-item two-line :key="item.id" v-for="(item, index) in fakeMedicalHistory">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ index + 1 }}. {{ item.name }}
                            </v-list-item-title>
                            <v-spacer></v-spacer>
                            <span class="ml-4 mb-1 grey--text text--darken-1">
                              <small class="text-uppercase blue--text text--darken-3">
                                Treatment &roarr;
                              </small>
                              {{ item.medication }}
                            </span>
                            <v-list-item-subtitle>
                              <small class="ml-4">{{ item.date }}</small>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <h3 class="my-5">Coming soon</h3>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>

          <v-btn link outlined color="orange darken-2" class="my-3" :href="`https://esupport.uniuyo.edu.ng`">
            Student Portal ESupport
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-card :color="invalidCard ? 'red' : ''">
        <v-card-text class="text-center">
          <h1 class="my-15">{{ info }}</h1>
        </v-card-text>
      </v-card>
    </div>
    <v-divider class="mt-10"></v-divider>
    <v-row class="py-6">
      <v-col>
        <InformationBoard />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import UpdateInfo from "../Dialogs/UpdateInfo.vue";
import StudentResults from "./Includes/StudentResults";
import InformationBoard from "../../utils/InformationBoard";
import { formatIdentity, formatDate } from "../../../tools/format";
import { identityStatuses as STATUSES } from "../../../tools/specs";
import {
  FAKE_MEDICAL_HISTORY,
  FAKE_FAMILY_HISTORY,
} from "../../../helpers/medical-info";

export default {
  components: {
    QrcodeVue,
    StudentResults,
    InformationBoard,
    UpdateInfo
  },

  props: ["identity"],

  data: () => ({
    loading: false,
    // identity: null,
    studentIdentity: null,
    tab: null,

    info: "Verifying ..",
    invalidCard: false,

    person: null,
    profileFound: false,
    librarySubscriptions: [],

    resultsFetched: false,
    fetchingResults: false,
    studentResults: [],

    fakeMedicalHistory: FAKE_MEDICAL_HISTORY,
    fakeFamilyHistory: FAKE_FAMILY_HISTORY,

    baseURL: process.env.VUE_APP_VERIFY_URL,
  }),

  methods: {
    formatDate,
    formatIdentity,
    statusColor(status) {
      const STATUS = status.toUpperCase();

      switch (STATUS) {
        case STATUSES[0]:
          return "green";
        case STATUSES[1]:
          return "red";
        case STATUSES[2]:
          return "brown";
        case STATUSES[3]:
          return "orange";
        default:
          return "";
      }
    },
    verifyIdentity() {
      this.info = "Verifying ..";
      this.cardStatus = null;

      this.$axios
        .get(`/identity/${this.identity}`)
        .then(({ data: response }) => {
          const { person, subscriptions, lastRegistration } = response;

          this.studentIdentity = response;
          this.person = person;
          this.librarySubscriptions = [...subscriptions].filter(
            (s) => (s.type = "library")
          );
          this.lastRegistration = lastRegistration;
          this.profileFound = !!response.identity;
        })
        .catch((error) => {
          const status = error.response.status;

          if (status == 404) {
            this.invalidCard = true;
            this.info = "Invalid card!";
          } else {
            this.info = "Something went wrong... try again.";
          }

          this.profileFound = false;

          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Profile not found.",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchResults() {
      if (!this.fetchingResults) {
        this.fetchingResults = true;

        this.$axios
          .get(`/results/${this.identity}`)
          .then(({ data: response }) => {
            this.studentResults = response;
            this.resultsFetched = !!response;
          })
          .catch(() => {
            this.$bus.$emit("snackbar", {
              type: "error",
              message: "Could not load results",
            });
          })
          .finally(() => {
            this.fetchingResults = false;
          });
      }
    },
    openESupport() {
      const eSupportURL = new URLSearchParams('https://esupport.uniuyo.edu.ng');

      this.person &&
        eSupportURL.append('student', this.person.matric);

      window.location.replace(eSupportURL.toString());
    }
  },

  mounted() {
    this.verifyIdentity();
  },
};
</script>
