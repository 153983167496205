var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',[_vm._v("Student Info")]),_c('v-card',{attrs:{"flat":"","max-width":"420px"}},[_c('v-form',{staticClass:"mt-5",on:{"submit":function($event){$event.preventDefault();return _vm.findStudent.apply(null, arguments)}},model:{value:(_vm.findStudentFormValid),callback:function ($$v) {_vm.findStudentFormValid=$$v},expression:"findStudentFormValid"}},[_c('v-text-field',{staticClass:"identification",attrs:{"outlined":"","clearable":"","hide-details":"","prepend-inner-icon":"mdi-school","label":"Matriculation Number *"},model:{value:(_vm.registrationNumber),callback:function ($$v) {_vm.registrationNumber=$$v},expression:"registrationNumber"}}),_c('v-btn',{staticClass:"mt-3",attrs:{"large":"","loading":_vm.loading,"disabled":_vm.loading,"type":"submit","color":"primary","outlined":""},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',[_vm._v("Finding...")])]},proxy:true}])},[_vm._v(" Find Student "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-account-search-outline")])],1)],1)],1),(_vm.studentFound.id > 0)?_c('div',[_c('v-divider',{staticClass:"mt-7 mb-5"}),_c('h2',[_vm._v("Student Details")]),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"sm":"12","md":"3"}},[_c('v-card',[_c('v-card-text',[_c('v-img',{staticClass:"shrink passport-image mx-auto",attrs:{"cover":"","src":`${
                _vm.studentFound.passport
                  ? `https://uniuyo.edu.ng/eportals/passports/${_vm.studentFound.passport}`
                  : '/favicon.png'
              }`,"width":_vm.$vuetify.breakpoint.smAndDown ? '230px' : '',"transition":"scale-transition","aspect-ratio":"1"}})],1)],1)],1),_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Student Information ")]),_c('v-tabs',{attrs:{"dark":"","show-arrows":"","color":"green lighten-2"}},[_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account ")]),_vm._v(" Personal ")],1),_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-school ")]),_vm._v(" Academic ")],1),_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-bookshelf ")]),_vm._v(" Courses ")],1),_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-check-decagram-outline ")]),_vm._v(" Results ")],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('table',{staticClass:"table"},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Full Name")]),_c('td',[_vm._v(_vm._s(_vm.studentFound.name))])]),_c('tr',[_c('td',[_vm._v("Date of Birth")]),_c('td',[_vm._v(_vm._s(_vm.studentFound.dob))])]),_c('tr',[_c('td',[_vm._v("Gender")]),_c('td',[_vm._v(_vm._s(_vm.studentFound.gender))])]),_c('tr',[_c('td',[_vm._v("Email")]),_c('td',[_vm._v(_vm._s(_vm.studentFound.email))])]),_c('tr',[_c('td',[_vm._v("Phone")]),_c('td',[_vm._v(_vm._s(_vm.studentFound.phone))])])])])])],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('table',{staticClass:"table"},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Matriculation Number")]),_c('td',[_vm._v(_vm._s(_vm.studentFound.matric))])]),_c('tr',[_c('td',[_vm._v("Course of Study")]),_c('td',[_vm._v(_vm._s(_vm.studentFound.course))])]),_c('tr',[_c('td',[_vm._v("Department")]),_c('td',[_vm._v(_vm._s(_vm.studentFound.department))])]),_c('tr',[_c('td',[_vm._v("Faculty")]),_c('td',[_vm._v(_vm._s(_vm.studentFound.faculty))])])])])])],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[(
                      _vm.studentFound.lastRegistration.coursesRegistered &&
                      _vm.studentFound.lastRegistration.coursesRegistered[
                        'length'
                      ] > 0
                    )?_c('div',[_c('div',{staticStyle:{"text-align":"right"}},[_c('h3',[_vm._v(" Registration for "+_vm._s(_vm.studentFound.lastRegistration.sessionRegistered)+" "+_vm._s(_vm.studentFound.lastRegistration.semesterRegistered)+" Semester ")]),_c('p',[_vm._v(" Date Registered: "+_vm._s(_vm.studentFound.lastRegistration.dateRegistered)+" ")])]),_c('table',{staticClass:"table"},[_c('tbody',[_c('tr',[_c('th',[_vm._v("Course Code")]),_c('th',[_vm._v("Course Title")]),_c('th',[_vm._v("Credit Hours")])]),_vm._l((_vm.studentFound.lastRegistration
                            .coursesRegistered),function(course){return _c('tr',{key:course.CourseID},[_c('td',[_vm._v(_vm._s(course.CourseCode))]),_c('td',{staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(course.CourseTitle)+" ")]),_c('td',[_vm._v(_vm._s(course.CH))])])})],2)])]):_c('p',{staticClass:"my-5 overline red--text"},[_vm._v(" No registration for this semester! ")])])],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[(!_vm.resultsFetched)?_c('v-btn',{staticClass:"my-3",attrs:{"depressed":"","color":"lime","loading":_vm.fetchingResults,"disabled":_vm.fetchingResults},on:{"click":_vm.fetchResults}},[_vm._v(" Load results "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-cloud-sync")])],1):_vm._e(),(_vm.resultsFetched)?_c('div',[(_vm.studentResults.length > 0)?_c('section',[_c('StudentResults',{attrs:{"results":_vm.studentResults}})],1):_c('h3',{staticClass:"my-5"},[_vm._v("No results found")])]):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }