export const FAKE_FAMILY_HISTORY = [
  "Arthritis",
  "Diabetes",
  "Hay fever",
  "Problems with anaesthesia",
];

export const FAKE_MEDICAL_HISTORY = [
  {
    id: 1,
    name: "Melanoma, Typhoid, Migraine",
    medication:
      "acetaminophen tabs (3/7), amoxil tabs (14/30), paracetamol tabs (14/30)",
    date: "14/04/2021",
  },
  {
    id: 2,
    name: "Bulimia",
    medication: "prozac (14 days)",
    date: "08/01/2019",
  },
  {
    id: 3,
    name: "Anorexia nervosa",
    medication: "olanzapine (30 days), cyproheptadine (30 days)",
    date: "23/09/2018",
  },
  {
    id: 4,
    name: "Constipation, Malaria",
    medication: "dulcolax, artemether / lumefantrine (3/7)",
    date: "18/03/2018",
  },
];
