<template>
  <div>
    <h2>Bulk student identities</h2>
    <v-card flat>
      <v-form
        class="mt-5"
        ref="fetchStudentsForm"
        v-model="fetchStudentsFormValid"
        @submit.prevent="queryStudents"
      >
        <v-row dense>
          <v-col lg="3" sm="6" cols="12">
            <v-select
              outlined
              label="Active session"
              v-model="session"
              hide-details
              clearable
              :rules="required"
              :items="sessions"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col lg="3" sm="6" cols="12">
            <v-select
              outlined
              label="Programme"
              v-model="programme"
              hide-details
              clearable
              multiple
              :items="programmes"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col lg="3" sm="6" cols="12">
            <v-autocomplete
              outlined
              label="Faculty"
              v-model="faculty"
              hide-details
              clearable
              :items="faculties"
              item-text="name"
              item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col lg="3" sm="6" cols="12">
            <v-autocomplete
              outlined
              label="Department"
              v-model="department"
              hide-details
              clearable
              :items="filteredDepartments"
              item-text="name"
              item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col lg="3" sm="6" cols="12">
            <v-autocomplete
              outlined
              label="Course"
              v-model="course"
              hide-details
              clearable
              :items="filteredCourses"
              item-text="name"
              item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col lg="3" sm="6" cols="12">
            <v-select
              outlined
              label="Level"
              v-model="level"
              hide-details
              clearable
              :items="levels"
              item-text="label"
              item-value="value"
            ></v-select>
          </v-col>
        </v-row>

        <div class="mt-3">
          <v-btn
            large
            outlined
            :loading="searching"
            :disabled="
              searching ||
              !fetchStudentsFormValid ||
              !(programme || faculty || department || course || level)
            "
            class="mr-2 my-1"
            type="submit"
            color="primary"
          >
            Load Students <v-icon right>mdi-magnify</v-icon>
            <template v-slot:loader>
              <span>Loading...</span>
            </template>
          </v-btn>
          <v-btn
            large
            outlined
            color="red accent-2"
            class="my-1 white--text"
            @click="clearFilters"
          >
            Clear Filters <v-icon right>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-form>
    </v-card>

    <div v-if="students.length > 0" class="my-5">
      <v-divider class="my-7"></v-divider>

      <div class="d-flex mb-2">
        <h2>{{ meta.total }} <span class="grey--text">students found</span></h2>
        <v-spacer></v-spacer>
        <v-btn
          large
          color="green"
          :loading="creating"
          :disabled="creating"
          @click="createProfiles"
        >
          Create Identities <v-icon right>mdi-shield-link-variant</v-icon>
        </v-btn>
      </div>
      <v-data-table
        :headers="headers"
        :items="students"
        class="elevation-1"
        hide-default-footer
        loading-text="Loading... Please wait"
        :footer-props="tableFooterProps"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-row align="center">
            <v-col>
              {{ item.name }}
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.hasIdentity`]="{ item }">
          <v-icon :color="item.hasIdentity ? 'green' : 'grey'">
            {{ item.hasIdentity ? "mdi-check-all" : "mdi-cancel" }}
          </v-icon>
        </template>
      </v-data-table>
      <div class="text-right pt-5">
        <v-pagination
          circle
          color="green darken-2"
          total-visible="7"
          @input="fetchStudents"
          v-model="meta.current_page"
          :length="meta.last_page"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "../../../tools/rules";
import { formatIdentity, formatDate } from "../../../tools/format";

export default {
  data: () => ({
    page: 1,
    pageCount: 0,
    students: [],
    searching: false,
    creating: false,
    headers: [
      {
        text: "Student",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Gender", value: "gender" },
      { text: "Reg. Number", value: "matric" },
      { text: "Faculty", value: "faculty" },
      { text: "Department", value: "department" },
      { text: "Has Identity", value: "hasIdentity", align: "center" },
    ],
    tableFooterProps: {
      itemsPerPageOptions: [-1],
    },
    meta: {
      total: 0,
      last_page: 0,
      current_page: 0,
    },

    query: null,

    required,
    fetchStudentsFormValid: false,

    levels: [
      { label: "100", value: 1 },
      { label: "200", value: 2 },
      { label: "300", value: 3 },
      { label: "400", value: 4 },
      { label: "500", value: 5 },
      { label: "600", value: 6 },
      { label: "700", value: 7 },
      { label: "800", value: 8 },
      { label: "900", value: 9 },
      { label: "1000", value: 10 },
      { label: "1100", value: 11 },
      { label: "1200", value: 12 },
      { label: "1300", value: 13 },
      { label: "1400", value: 14 },
      { label: "1500", value: 15 },
      { label: "1600", value: 16 },
      { label: "1700", value: 17 },
      { label: "1800", value: 18 },
      { label: "1900", value: 19 },
      { label: "2000", value: 20 },
    ],

    courses: [],
    faculties: [],
    programmes: [],
    departments: [],
    sessions: [],

    level: null,
    course: null,
    session: null,
    faculty: null,
    programme: null,
    department: null,

    // baseURL: process.env.VUE_APP_VERIFY_URL,
  }),

  computed: {
    filteredDepartments() {
      if (this.faculty) {
        return [...this.departments].filter((d) => d.faculty == this.faculty);
      } else return [...this.departments];
    },
    filteredCourses() {
      if (this.faculty && this.department) {
        return [...this.courses].filter(
          (c) => c.faculty == this.faculty && c.department == this.department
        );
      } else if (this.department) {
        return [...this.courses].filter((c) => c.department == this.department);
      } else return [...this.courses];
    },
  },

  methods: {
    formatDate,
    formatIdentity,

    clearFilters() {
      this.searching = false;
      this.level = null;
      this.course = null;
      this.faculty = null;
      this.programme = null;
      this.department = null;
      this.students = [];
      this.$refs.fetchStudentsForm.reset();
    },

    composeQuery(page) {
      let query = `
        ${this.page ? `page=${page}&` : ""}
        ${this.level ? `level=${this.level}&` : ""}
        ${this.session ? `session=${this.session}&` : ""}
        ${this.programme ? `programme=${this.programme}&` : ""}
        ${this.faculty ? `faculty=${this.faculty}&` : ""}
        ${this.department ? `department=${this.department}&` : ""}
        ${this.course ? `course=${this.course}&` : ""}
      `
        .slice(0, -1)
        .replace(/\s+/g, "");

      return String(query).slice(0, -1).replace(/\s+/g, "");
    },

    queryStudents() {
      this.fetchStudents(1);
    },

    fetchStudents(page) {
      let query = this.composeQuery(page);
      this.query = query;
      this.searching = true;

      this.$axios
        .get(`/students?${query}`)
        .then(async ({ data: { data: students, meta } }) => {
          if (students && students.length > 0) {
            this.students = students;
            this.meta = meta;
          }
        })
        .finally(() => {
          this.searching = false;
        });
    },

    createProfiles() {
      this.creating = true;

      this.$axios
        .post(`/identities?${this.query}`)
        .then(({ data: response }) => {
          const { created } = response;
          this.$bus.$emit("snackbar", {
            type: "info",
            message: `${created} new identities created!`,
          });
          this.queryStudents();
        })
        .catch(() => {
          this.$bus.$emit("snackbar", {
            type: "error",
            message: `Something went wrong`,
          });
        })
        .finally(() => {
          this.creating = false;
        });
    },

    fetchSessions() {
      this.$axios.get("/sessions").then(({ data: sessions }) => {
        this.sessions = [...sessions];
      });
    },

    fetchProgrammes() {
      this.$axios.get("/programmes").then(({ data: programmes }) => {
        this.programmes = [...programmes].sort((a, b) =>
          a.name.trim() > b.name.trim() ? 1 : -1
        );
      });
    },

    fetchFaculties() {
      this.$axios.get("/faculties").then(({ data: faculties }) => {
        this.faculties = [...faculties].sort((a, b) =>
          a.name.trim() > b.name.trim() ? 1 : -1
        );
      });
    },

    fetchDepartments() {
      this.$axios.get("/departments").then(({ data: departments }) => {
        this.departments = [...departments].sort((a, b) =>
          a.name.trim() > b.name.trim() ? 1 : -1
        );
      });
    },

    fetchCourses() {
      this.$axios.get("/courses").then(({ data: courses }) => {
        this.courses = [...courses].sort((a, b) =>
          a.name.trim() > b.name.trim() ? 1 : -1
        );
      });
    },
  },

  mounted() {
    this.fetchSessions();
    this.fetchProgrammes();
    this.fetchFaculties();
    this.fetchDepartments();
    this.fetchCourses();
  },
};
</script>
