import Library from "../components/Library/LibraryCard";

const routes = [
  {
    path: "/library",
    name: "library",
    component: Library,
    meta: { requiresAuth: true },
  },
];

export default routes;
