<template>
  <div>
    <h2>Bulk student identities</h2>
    <v-card flat>
      <v-form
        class="mt-5"
        v-model="findStudentFormValid"
        @submit.prevent="findStudent"
      >
        <v-row>
          <v-col>
            <v-select
              outlined
              label="Faculty"
              prepend-inner-icon="mdi-school"
              v-model="registrationNumber"
              hide-details
              clearable
              :items="faculties"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              label="Department"
              prepend-inner-icon="mdi-school"
              v-model="registrationNumber"
              hide-details
              clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              label="Level"
              prepend-inner-icon="mdi-school"
              v-model="registrationNumber"
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>

        <v-btn
          large
          :loading="loading"
          :disabled="loading"
          class="mt-3"
          type="submit"
          color="primary"
          outlined
        >
          Find Student <v-icon right>mdi-account-search-outline</v-icon>
          <template v-slot:loader>
            <span>Finding...</span>
          </template>
        </v-btn>
      </v-form>
    </v-card>

    <div v-if="studentFound.id > 0">
      <v-divider class="mt-7 mb-5"></v-divider>

      <h2>Student details</h2>

      <v-row class="mt-2">
        <v-col sm="12" md="3">
          <v-img
            class="shrink passport-image mx-auto"
            cover
            :src="`${
              studentFound.passport
                ? `https://uniuyo.edu.ng/eportals/passports/${studentFound.passport}`
                : '/favicon.png'
            }`"
            :width="$vuetify.breakpoint.smAndDown ? '230px' : ''"
            transition="scale-transition"
            aspect-ratio="1"
          >
          </v-img>
          <div v-if="studentFound.identity">
            <p class="identity mt-3">
              <v-chip outlined small label color="pink" class="white--text">
                {{ formatIdentity(studentFound.identity) }}
              </v-chip>
            </p>

            <div class="qrcode">
              <qrcode-vue
                :value="`${baseURL}${studentFound.identity}`"
                :size="$vuetify.breakpoint.smAndDown ? 100 : 180"
                level="H"
              ></qrcode-vue>
            </div>

            <p class="text-center">
              <v-btn text :to="`/verify/${studentFound.identity}`">
                Verify
              </v-btn>
            </p>
          </div>
        </v-col>
        <v-col>
          <v-card>
            <v-tabs dark show-arrows color="green lighten-2">
              <v-tab>
                <v-icon left> mdi-account </v-icon>
                Personal
              </v-tab>
              <v-tab>
                <v-icon left> mdi-school </v-icon>
                Academic
              </v-tab>
              <v-tab>
                <v-icon left> mdi-bookshelf </v-icon>
                Courses
              </v-tab>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Full Name</td>
                          <td>{{ studentFound.name }}</td>
                        </tr>
                        <tr>
                          <td>Date of Birth</td>
                          <td>{{ studentFound.dob }}</td>
                        </tr>
                        <tr>
                          <td>Gender</td>
                          <td>{{ studentFound.gender }}</td>
                        </tr>
                        <!-- <tr>
                          <td>Blood Group</td>
                          <td>{{ studentFound.bloodGroup }}</td>
                        </tr> -->
                        <tr>
                          <td>Email</td>
                          <td>{{ studentFound.email }}</td>
                        </tr>
                        <tr>
                          <td>Phone</td>
                          <td>{{ studentFound.phone }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Matriculation Number</td>
                          <td>{{ studentFound.matric }}</td>
                        </tr>

                        <tr>
                          <td>Course of Study</td>
                          <td>{{ studentFound.course }}</td>
                        </tr>

                        <tr>
                          <td>Department</td>
                          <td>{{ studentFound.department }}</td>
                        </tr>

                        <tr>
                          <td>Faculty</td>
                          <td>{{ studentFound.faculty }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <div
                      v-if="
                        studentFound.lastRegistration.coursesRegistered &&
                        studentFound.lastRegistration.coursesRegistered[
                          'length'
                        ] > 0
                      "
                    >
                      <div style="text-align: right">
                        <h3>
                          Registration for
                          {{ studentFound.lastRegistration.sessionRegistered }}
                          {{
                            studentFound.lastRegistration.semesterRegistered
                          }} Semester
                        </h3>
                        <p>
                          Date Registered:
                          {{ studentFound.lastRegistration.dateRegistered }}
                        </p>
                      </div>
                      <table class="table">
                        <tbody>
                          <tr>
                            <th>Course Code</th>
                            <th>Course Title</th>
                            <th>Credit Hours</th>
                          </tr>
                          <tr
                            v-for="course in studentFound.lastRegistration
                              .coursesRegistered"
                            :key="course.CourseID"
                          >
                            <td>{{ course.CourseCode }}</td>
                            <td style="text-align: left">
                              {{ course.CourseTitle }}
                            </td>
                            <td>{{ course.CH }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p class="my-5 overline red--text" v-else>
                      No registration for this semester!
                    </p>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>

          <div v-if="!studentFound.identity" class="mt-5">
            <v-btn
              large
              color="green"
              :loading="creating"
              :disabled="creating"
              @click="createProfile"
            >
              Create identity <v-icon right>mdi-shield-link-variant</v-icon>
              <template v-slot:loader>
                <span>Creating...</span>
              </template>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { required } from "../../../tools/rules";
import { formatIdentity, formatDate } from "../../../tools/format";
import QrcodeVue from "qrcode.vue";

export default {
  components: {
    QrcodeVue,
  },

  data: () => ({
    rules: {
      required,
    },
    loading: false,
    studentFound: {
      id: 0,
      matric: null,
      lastRegistration: { coursesRegistered: [] },
    },
    registrationNumber: null,
    findStudentFormValid: false,

    tab: null,

    creating: false,
    librarySubscriptions: [],

    faculties: [],

    baseURL: process.env.VUE_APP_VERIFY_URL,
  }),

  watch: {
    registrationNumber(val) {
      if (val) this.registrationNumber = val.toUpperCase();
    },
  },

  methods: {
    formatDate,
    formatIdentity,
    findStudent() {
      if (!this.registrationNumber) return;

      this.loading = true;
      this.$axios
        .get("/student", { params: { registration: this.registrationNumber } })
        .then(({ data: response }) => {
          this.studentFound = response;
          const { subscriptions } = response;

          this.librarySubscriptions = [...(subscriptions || [])].filter(
            (s) => (s.type = "library")
          );
        })
        .catch((error) => {
          console.log(error);

          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Student not found",
          });
        })
        .finally(() => {
          this.loading = false;
          // this.registrationNumber = null;
        });
    },
    createProfile() {
      if (!this.studentFound.id) return;

      this.creating = true;
      this.$axios
        .post("/identity", { studentId: this.studentFound.id })
        .then(({ data: response }) => {
          console.log(response);

          this.findStudent();
        })
        .catch((error) => {
          console.log(error);

          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Could not create identity",
          });
        })
        .finally(() => {
          this.creating = false;
        });
    },

    fetchFaculties() {
      this.$axios
        .get("/faculties")
        .then(({ data: response }) => {
          this.faculties = [...response].sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
        })
        .catch(() => {
          this.$bus.$emit("snackbar", {
            type: "warning",
            message: "Could not load faculties",
          });
        });
    },
  },

  mounted() {
    this.fetchFaculties();
  },
};
</script>
