<template>
  <div>
    <v-container class="pt-2">
      <div class="mb-5">
        <v-btn
          tile
          depressed
          color="green lighten-5"
          class="green--text mr-1 mb-1"
          :to="item.location"
          v-for="item in userMenu"
          :key="item.location"
        >
          {{ item.title }}
        </v-btn>
      </div>
      <router-view :key="$route.fullPath"></router-view>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    menu: [
      {
        title: "Latest",
        location: "/identity/students/latest",
        access: ["admin"],
      },
      {
        title: "Profiles",
        location: "/identity/students/profiles",
        access: ["admin"],
      },
      {
        title: "Print Folder",
        location: "/identity/students/print",
        access: ["admin", "identity"],
      },
      {
        title: "Create Identity",
        location: "/identity/students/create-single",
        access: ["admin", "identity"],
      },
      {
        title: "Create Bulk",
        location: "/identity/students/create-bulk",
        access: ["admin", "identity"],
      },
    ],
  }),

  computed: {
    userMenu() {
      const { type } = JSON.parse(localStorage.getItem("idmsUser"));
      return this.menu.filter((m) => m.access.indexOf(type) > -1);
    },
  },
};
</script>

<style>
</style>