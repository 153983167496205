import {
  format,
  formatDuration as dtFormatDuration,
  formatDistanceToNow,
} from "date-fns";

function formatComplaintNumber(number) {
  return String(number).padStart(6, "0");
}

function timeago(val) {
  return formatDistanceToNow(new Date(val), { addSuffix: true });
}

function formatDate(val) {
  // return format(new Date(val), "dd-MM-yyyy, HH:mm xxx");
  return format(new Date(val), "dd-MM-yyyy, p");
}

function formatCurrency(amount) {
  // return new Intl.NumberFormat().format(amount);
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "NGN",
  }).format(amount);
}

function formatDateOnly(val) {
  // return format(new Date(val), "dd-MM-yyyy, HH:mm xxx");
  return format(new Date(val), "dd-MM-yyyy");
}

function formatDuration(val) {
  return dtFormatDuration({ seconds: parseInt(val) }, { format: ["minutes"] });
}

function formatName(val) {
  return val
    .toLowerCase()
    .split(" ")
    .map((v) => v.substr(0, 1).toUpperCase() + v.substr(1))
    .join(" ");
}

function formatNameForIcon({ firstName, lastName }) {
  if (firstName && lastName)
    return `${firstName.substr(0, 1).toUpperCase()}${lastName
      .substr(0, 1)
      .toUpperCase()}`;
}

function formatIdentity(identity) {
  if (identity) {
    return String(identity)
      .substr(0, 8)
      .toUpperCase();
  }
}

export {
  timeago,
  formatDate,
  formatDateOnly,
  formatDuration,
  formatComplaintNumber,
  formatNameForIcon,
  formatName,
  formatIdentity,
  formatCurrency,
};
