<template>
  <v-app @scroll="handleScroll">
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          contain
          width="40"
          alt="IDMS Logo"
          class="shrink mr-2"
          src="https://user-images.githubusercontent.com/46636890/100394092-2ad97f00-303c-11eb-8b9c-3b36dc43f095.png"
          transition="scale-transition"
        />

        <h2 v-if="$vuetify.breakpoint.mdAndUp">Identity Management System</h2>
        <h2 v-else>IDMS</h2>
        <v-form class="ml-3 mt-7" style="width: 400px" v-if="showSearchForm">
          <v-text-field
            dense
            full-width
            filled
            single-line
            type="search"
            clearable
            placeholder="Enter search text"
          >
            <template v-slot:label> Search </template>
          </v-text-field>
        </v-form>
      </div>

      <v-spacer></v-spacer>

      <v-btn to="/student-union" text v-if="!authenticated">
        <span>Student Union</span>
        <v-icon right>mdi-town-hall</v-icon>
      </v-btn>

      <span v-if="authenticated && $vuetify.breakpoint.mdAndUp">
        <!-- <v-btn
          text
          class="mr-2"
          v-if="administrator || desk"
          @click="showSearchForm = !showSearchForm"
        >
          <span>Search</span>
          <v-icon right>mdi-account-search</v-icon>
        </v-btn> -->

        <v-btn to="/staff" text class="mr-2" v-if="administrator">
          <span>Users</span>
          <v-icon right>mdi-account</v-icon>
        </v-btn>

        <v-btn v-if="academic" to="/student-info" text class="mr-2">
          <span>Student Info</span>
          <v-icon right>mdi-magnify</v-icon>
        </v-btn>

        <v-btn v-if="administrator || security" to="/search" text class="mr-2">
          <span>Search</span>
          <v-icon right>mdi-magnify</v-icon>
        </v-btn>

        <v-btn v-if="administrator || library" to="/library" text class="mr-2">
          <span>Library</span>
          <v-icon right>mdi-library</v-icon>
        </v-btn>

        <v-btn
          v-if="administrator || desk || identity"
          to="/identity/students"
          text
          class="mr-2"
        >
          <span>Students ID</span>
          <v-icon right>mdi-chair-school</v-icon>
        </v-btn>

        <v-btn
          v-if="administrator || desk || identity"
          to="/identity/staff"
          text
          class="mr-2"
        >
          <span>Staff ID</span>
          <v-icon right>mdi-chair-rolling</v-icon>
        </v-btn>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              class="mr-2"
              v-bind="attrs"
              @click="logoutUser"
            >
              <v-icon>mdi-lock-outline</v-icon>
            </v-btn>
          </template>
          <span>Logout</span>
        </v-tooltip>

        <v-progress-circular
          :size="24"
          color="white"
          indeterminate
          class="ma-2"
          v-if="requesting"
        ></v-progress-circular>
        <Avatar v-else :person="user" :class="'alt'" />
      </span>

      <v-progress-circular
        :size="20"
        color="white"
        indeterminate
        class="mr-2"
        v-if="requesting && $vuetify.breakpoint.smAndDown"
      ></v-progress-circular>

      <v-app-bar-nav-icon
        @click="openDrawer = !openDrawer"
        v-if="authenticated && $vuetify.breakpoint.smAndDown"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer
      v-if="openDrawer"
      v-model="openDrawer"
      floating
      app
      right
      dark
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <Avatar :person="user" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ user.firstName }} {{ user.lastName }}
            </v-list-item-title>
            <v-list-item-subtitle class="text-uppercase">
              <small>{{ user.type }} Officer</small>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item-group active-class="green--text text--accent-4">
          <v-list-item class="mb-1" to="/staff" v-if="administrator">
            <v-list-item-icon>
              <v-icon small>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <span class="text-uppercase" style="font-weight: 500">
                System Users
              </span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            class="mb-1"
            v-if="administrator || security"
            to="/search"
          >
            <v-list-item-icon>
              <v-icon small>mdi-magnify</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <span class="text-uppercase" style="font-weight: 500">
                Search
              </span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item class="mb-1" v-if="academic" to="/student-info">
            <v-list-item-icon>
              <v-icon small>mdi-magnify</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <span class="text-uppercase" style="font-weight: 500">
                Student Info
              </span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            class="mb-1"
            to="/library"
            v-if="administrator || library"
          >
            <v-list-item-icon>
              <v-icon small>mdi-library</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <span class="text-uppercase" style="font-weight: 500">
                Library
              </span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            class="mb-1"
            to="/identity/students"
            v-if="administrator || desk || identity"
          >
            <v-list-item-icon>
              <v-icon small>mdi-chair-school</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <span class="text-uppercase" style="font-weight: 500">
                Student Identity
              </span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            class="mb-1"
            to="/identity/staff"
            v-if="administrator || desk || identity"
          >
            <v-list-item-icon>
              <v-icon small>mdi-chair-rolling</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <span class="text-uppercase" style="font-weight: 500">
                Staff Identity
              </span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item class="mb-1" @click="logoutUser">
            <v-list-item-icon>
              <v-icon small>mdi-lock-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <span class="text-uppercase" style="font-weight: 500">
                Logout
              </span>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container class="pt-7">
        <router-view :key="$route.fullPath"></router-view>
      </v-container>
    </v-main>

    <v-fab-transition>
      <v-btn
        v-if="showScrollTopButton"
        @click="scrollToTop"
        dark
        large
        fixed
        bottom
        right
        fab
        color="pink"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>

    <Snackbar />
  </v-app>
</template>

<script>
import Avatar from "./components/utils/Avatar";
import Snackbar from "./components/utils/SnackBar";

export default {
  name: "App",

  components: {
    Avatar,
    Snackbar,
  },

  data: () => ({
    openDrawer: false,
    showScrollTopButton: false,

    user: {
      firstName: "Abel",
      lastName: "Bronwick",
      type: "",
    },

    showSearchForm: false,
    authenticated: false,
    desk: false,
    library: false,
    academic: false,
    administrator: false,
    security: false,
    requesting: false,
  }),

  methods: {
    async logoutUser() {
      const authenticated = !!localStorage.getItem("idmsAuth");
      if (authenticated) {
        await this.$axios.post("/logout");
      }
      localStorage.removeItem("idmsAuth");
      localStorage.removeItem("idmsUser");

      if (this.$router.currentRoute.name != "login") {
        this.$router.push({ name: "login" });
      }

      this.refereshAuth();
    },
    refereshAuth() {
      const authenticated = !!localStorage.getItem("idmsAuth");
      this.authenticated = authenticated;

      if (authenticated) {
        this.user = JSON.parse(localStorage.getItem("idmsUser"));

        this.desk = this.user.type == "desk";
        this.library = this.user.type == "library";
        this.identity = this.user.type == "identity";
        this.administrator =
          this.user.type == "admin" || this.user.type == "root";
        this.academic = this.user.type == "academic";
        this.security = this.user.type == "security";
      } else {
        localStorage.removeItem("idmsAuth");
        localStorage.removeItem("idmsUser");
        this.user = {
          role: 0,
          type: null,
          active: false,
          verified: false,
          name: "",
        };
      }
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        (document.documentElement || document.body.parentNode || document.body)
          .scrollTop;

      this.showScrollTopButton = scrollTop > 300;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  mounted() {
    this.$bus.$on("request.initiated", () => (this.requesting = true));
    this.$bus.$on("request.ended", () => (this.requesting = false));

    this.refereshAuth();

    this.$bus.$on("login", this.refereshAuth);
    this.$bus.$on("logout", this.logoutUser);

    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">
html,
body,
.v-application {
  line-height: 1.5;
  scroll-behavior: smooth;
  scroll-padding-top: 2em;
  font-family: "Inter", "Roboto", Geneva, Verdana, sans-serif !important;

  .option {
    p {
      margin-bottom: 0 !important;
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  thead {
    tr {
      th {
        text-transform: uppercase;
      }
    }
  }

  tr {
    border-left: 5px solid #c3e7cd;
    border-left: 5px solid #eee1ad;
    border-bottom: thin solid #dddddd;
  }

  tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  tr {
    th,
    td {
      padding: 0.75em 1em;
      margin: 0;
    }

    th {
      text-align: left;
    }

    td:nth-child(2) {
      font-weight: 500;
      text-align: right;
    }
  }
}

.passport-image {
  border-radius: 0.3em;
}

.identity {
  text-align: center;
  letter-spacing: 0.2em;
}

.qrcode {
  margin: 1em 0;
  text-align: center;
}

.listing {
  margin: 0;
  list-style: none;
  padding: 0 !important;
  border-bottom: thin solid #dddddd;

  li {
    padding: 0.66em 1em;
    background-color: #efefef;
    border-left: 5px solid #eee1ad;
  }
}

.cursor {
  cursor: pointer;
}

.identification {
  word-break: keep-all;
  white-space: nowrap;

  select,
  input {
    text-transform: uppercase;
  }
}
</style>