<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Delete identity </v-card-title>
        <v-card-text>
          Are you sure you want to delete this identity?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog"> Cancel </v-btn>
          <v-btn color="red darken-1" text @click="deleteProfile">
            Yes, delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["identity", "open"],

  data: () => ({
    dialog: false,
  }),

  watch: {
    open(value) {
      this.dialog = value;
    },
  },

  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.$emit("closed");
      this.dialog = false;
    },
    deleteProfile() {
      this.$axios
        .delete(`/identities/staff?identity=${this.identity}`)
        .then(() => {
          this.$bus.$emit("snackbar", {
            type: "info",
            message: "Staff identity has been deleted",
          });
          this.dialog = false;

          this.$bus.$emit("identity.deleted");
          this.$bus.$emit("identities.reload");
        })
        .catch(() => {
          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Could not delete staff identity",
          });
        })
        .finally(() => {
          this.marking = false;
        });
    },
  },
};
</script>