<template>
  <div>
    <h2>Create Staff Identity</h2>
    <v-card flat max-width="420px">
      <v-form
        class="mt-5"
        v-model="findStaffFormValid"
        @submit.prevent="findStaff"
      >
        <v-text-field
          outlined
          clearable
          hide-details
          class="identification"
          v-model="staffNumber"
          prepend-inner-icon="mdi-card-account-details"
          label="Staff Number *"
        ></v-text-field>

        <v-btn
          large
          :loading="loading"
          :disabled="loading"
          class="mt-3"
          type="submit"
          color="primary"
          outlined
        >
          Find Staff <v-icon right>mdi-account-search-outline</v-icon>
          <template v-slot:loader>
            <span>Finding...</span>
          </template>
        </v-btn>
      </v-form>
    </v-card>

    <div v-if="staffFound.full_name">
      <v-divider class="mt-7 mb-5"></v-divider>

      <h2>Staff Details</h2>

      <v-row class="mt-2">
        <v-col sm="12" md="4">
          <v-card>
            <v-card-text>
              <v-form
                @submit.prevent=""
                name="staffOtherDetailsForm"
                v-model="staffOtherDetails"
                v-if="!staffFound.identity"
              >
                <v-file-input
                  outlined
                  show-size
                  hide-details
                  v-model="passport"
                  label="Select passport *"
                  accept="image/*"
                  prepend-inner-icon="mdi-file-image"
                  :rules="rules.requiredAttachment"
                ></v-file-input>
                <div v-if="preview">
                  <v-divider class="my-3"></v-divider>
                  <v-img
                    cover
                    height="230"
                    :src="preview"
                    aspect-ratio="1"
                    class="shrink mx-auto"
                    alt="Selected passport"
                    transition="scale-transition"
                    :width="$vuetify.breakpoint.smAndDown ? '230px' : '230px'"
                  ></v-img>
                </div>
                <v-select
                  outlined
                  class="mt-5"
                  hide-details
                  v-model="bloodGroup"
                  :items="bloodGroups"
                  :rules="rules.required"
                  label="Blood Group"
                  prepend-inner-icon="mdi-water"
                ></v-select>
              </v-form>
              <v-img
                v-else
                cover
                class="shrink passport-image mx-auto"
                :src="`${
                  staffFound.identity.passport
                    ? `${passportBaseURL}${staffFound.identity.passport}`
                    : '/favicon.png'
                }`"
                :width="$vuetify.breakpoint.smAndDown ? '230px' : ''"
                transition="scale-transition"
                aspect-ratio="1"
              >
              </v-img>
            </v-card-text>
          </v-card>

          <div v-if="staffFound.identity">
            <p class="identity mt-3 mb-7">
              <v-chip outlined small label color="pink" class="white--text">
                {{ formatIdentity(staffFound.identity.uid) }}
              </v-chip>
            </p>

            <div class="qrcode">
              <qrcode-vue
                :value="`${baseURL}${staffFound.identity.uid}`"
                :size="$vuetify.breakpoint.smAndDown ? 100 : 180"
                level="H"
              ></qrcode-vue>
            </div>

            <p class="text-center">
              <v-btn text :to="`/verify/staff/${staffFound.identity.uid}`">
                Verify
              </v-btn>
            </p>
          </div>
        </v-col>

        <v-col>
          <v-card>
            <v-card-title>
              Staff Information
              <v-spacer></v-spacer>
              <div v-if="staffFound.identity">
                <v-chip
                  class="white--text"
                  v-if="staffFound.identity.status"
                  :color="statusColor(staffFound.identity.status)"
                >
                  {{ staffFound.identity.status }}
                </v-chip>
                <v-btn
                  v-if="
                    String(staffFound.identity.status).toUpperCase() != 'ACTIVE'
                  "
                  :disabled="reissuing"
                  @click="issueNewCard"
                  :loading="reissuing"
                  class="ml-2"
                  color="lime"
                  small
                >
                  Issue New Card
                </v-btn>
              </div>
            </v-card-title>
            <v-tabs dark show-arrows color="red accent-2">
              <v-tab>
                <v-icon left> mdi-account </v-icon>
                Personal
              </v-tab>
              <v-tab>
                <v-icon left> mdi-cash-multiple </v-icon>
                Salary History
              </v-tab>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Full Name</td>
                          <td>{{ staffFound.full_name }}</td>
                        </tr>
                        <tr>
                          <td>Staff Number</td>
                          <td>{{ staffFound.uu_staff_id }}</td>
                        </tr>
                        <tr v-if="hasBloodGroup">
                          <td>Blood Group</td>
                          <td>{{ staffFound.identity.bloodGroup }}</td>
                        </tr>
                        <tr>
                          <td>IPPIS ID</td>
                          <td>{{ staffFound.ippis_staff_id }}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>{{ staffFound.email }}</td>
                        </tr>
                        <tr>
                          <td>Phone</td>
                          <td>(+234){{ staffFound.phone }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <p class="my-5 overline">
                      <strong>
                        Create identity and then click verify to see this.
                      </strong>
                    </p>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>

          <div v-if="!staffFound.identity" class="mt-5">
            <v-btn
              large
              color="green"
              :loading="creating"
              :disabled="creating || !staffOtherDetails"
              @click="createProfile"
            >
              Create identity <v-icon right>mdi-shield-link-variant</v-icon>
              <template v-slot:loader>
                <span>Creating...</span>
              </template>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { bloodGroups } from "../../../tools/specs";
import { required, attachment } from "../../../tools/rules";
import { statusColor } from "../../../tools/specs";
import { formatIdentity, formatDate } from "../../../tools/format";

import QrcodeVue from "qrcode.vue";

export default {
  components: {
    QrcodeVue,
  },

  data: () => ({
    rules: {
      required,
      requiredAttachment: [...required, ...attachment],
    },
    loading: false,

    staffFound: {
      id: 0,
      identity: {
        bloodGroup: null,
        status: null,
      },
    },
    passport: null,
    bloodGroup: null,
    staffNumber: null,
    findStaffFormValid: false,
    staffOtherDetails: false,

    tab: null,
    preview: null,
    bloodGroups,

    creating: false,
    reissuing: false,
    hasBloodGroup: false,

    baseURL: process.env.VUE_APP_STAFF_VERIFY_URL,
    passportBaseURL: process.env.VUE_APP_PASSPORT_URL,
  }),

  watch: {
    passport(value) {
      if (value) {
        this.preview = URL.createObjectURL(value);
      } else {
        this.preview = null;
      }
    },
  },

  methods: {
    formatDate,
    statusColor,
    formatIdentity,

    findStaff() {
      if (!this.staffNumber) return;

      const staffNumber = String(this.staffNumber)
        .trim()
        .toUpperCase()
        .split("UU/")
        .pop();

      this.loading = true;
      this.$axios
        .get("/staff", { params: { staffNumber } })
        .then(({ data: response }) => {
          this.staffFound = response;
          this.hasBloodGroup =
            (response.identity && response.identity.bloodGroup) || false;
        })
        .catch(() => {
          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Staff not found",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createProfile() {
      if (!this.staffFound.id) return;

      this.creating = true;

      let formData = new FormData();
      formData.append("passport", this.passport);
      formData.append("staffId", this.staffFound.id);
      formData.append("bloodGroup", this.bloodGroup);

      this.$axios
        .post("/identities/staff", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.findStaff();
        })
        .catch(() => {
          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Could not create identity",
          });
        })
        .finally(() => {
          this.creating = false;
        });
    },
    issueNewCard() {
      if (!this.staffFound.id) return;

      if (!this.reissuing) {
        this.reissuing = true;

        let formData = new FormData();
        // formData.append("passport", this.passport);
        formData.append("staffId", this.staffFound.id);
        // formData.append("bloodGroup", this.bloodGroup);

        this.$axios
          .post("/identities/staff/reissue", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.findStaff();
            this.$bus.$emit("snackbar", {
              type: "success",
              message: "A new staff identity has been issued",
            });
          })
          .catch(() => {
            this.$bus.$emit("snackbar", {
              type: "error",
              message: "Could not issue new identity",
            });
          })
          .finally(() => {
            this.reissuing = false;
          });
      }
    },
  },
};
</script>


