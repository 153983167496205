import Search from "../components/Identity/Students/Search";
import Info from "../components/Identity/Students/Info";

const routes = [
  {
    path: "/search",
    name: "students.search",
    component: Search,
  },
  {
    path: "/student-info",
    name: "student.info",
    component: Info,
  },
];

export default routes;
