<template>
  <div>
    <!-- <h1 class="mb-2">What is UNIUYO Identity Management System?</h1> -->

    <!-- <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque dolore,
      corporis odio voluptates tenetur optio eum doloremque fugit quis natus?
    </p> -->

    <!-- <v-divider class="mt-9"></v-divider> -->
    <v-row class="py-6">
      <v-col>
        <InformationBoard />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InformationBoard from "./utils/InformationBoard";
export default {
  components: {
    InformationBoard,
  },
};
</script>