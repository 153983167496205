<template>
  <v-snackbar
    bottom
    right
    class="snackbar"
    v-model="snackbar"
    :color="snackbar_color"
  >
    {{ snackbar_text }}

    <template v-slot:action="{ attrs }">
      <v-btn icon class="mr-2" v-bind="attrs" @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  data() {
    return {
      snackbar: false,
      snackbar_text: "",
      snackbar_color: "red",
    };
  },
  methods: {
    showSnackbar({ type = "default", message }) {
      this.snackbar_color = type;
      this.snackbar_text = message;
      this.snackbar = true;
    },
  },
  created() {
    this.$bus.$on("snackbar", this.showSnackbar);
  },
  beforeDestroy() {
    this.$bus.$off("snackbar");
  },
};
</script>

<style lang="scss" scoped>
.v-snack__wrapper.theme--dark.success {
  background-color: rgb(221, 66, 66);
  color: hsla(0, 0%, 100%, 0.87);
}
</style>