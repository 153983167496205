import Staff from "../components/Staff/Staff";
import Register from "../components/Staff/Register";
import Details from "../components/Staff/Details";

const routes = [
  {
    path: "/staff",
    name: "staff",
    component: Staff,
    meta: { requiresAuth: true, description: "Staff with access" },
  },
  {
    path: "/register",
    name: "staff.register",
    component: Register,
    meta: { requiresAuth: true, description: "Register new staff" },
  },
  {
    props: true,
    path: "/staff/:username",
    name: "staff.details",
    component: Details,
    meta: { requiresAuth: true, description: "Details of staff" },
  },
];

export default routes;
