<template>
  <div class="avatar" :class="type">
    <strong>{{ formatNameForIcon(person) }}</strong>
  </div>
</template>

<script>
import { formatNameForIcon } from "../../tools/format";

export default {
  props: {
    person: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
    },
  },
  methods: {
    formatNameForIcon,
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  display: inline-flex;
  width: 38px;
  height: 38px;
  background-color: #ffd7aa;
  text-align: center;
  border-radius: 50%;

  align-items: center;
  justify-content: center;
  user-select: none;
  transition: all 200ms ease;

  strong {
    color: #5f2c0f;
    letter-spacing: 0;
    text-decoration: none;
  }

  &:hover {
    background-color: #5de47f;

    strong {
      color: #124122;
    }
  }
}

.avatar.alt {
  background-color: #fcfffc;

  strong {
    color: #2d3a3a;
  }
}
</style>