import Identity from "../components/Identity/Students/Default";
import Profiles from "../components/Identity/Students/Profiles";
import PrintFolder from "../components/Identity/Students/PrintFolder";
import Latest from "../components/Identity/Students/Latest";
import CreateSingle from "../components/Identity/Students/CreateSingle";
import CreateBulk from "../components/Identity/Students/CreateBulk";
// import Statistics from "../components/Identity/Students/Statistics";

const routes = [
  {
    path: "/identity/students",
    component: Identity,
    redirect: "/identity/students/latest",
    meta: {
      requiresAuth: true,
      description: "Identity creation and management",
    },
    children: [
      // {
      //   path: "",
      //   name: "identity.students",
      //   component: Statistics,
      // },    
      {
        path: "latest",
        name: "identity.students.latest",
        component: Latest,
      },
      {
        path: "profiles",
        name: "identity.students.profiles",
        component: Profiles,
      },
      {
        path: "print",
        name: "identity.students.print",
        component: PrintFolder,
      },
      {
        path: "create-single",
        name: "identity.students.create_single",
        component: CreateSingle,
      },
      {
        path: "create-bulk",
        name: "identity.students.create_bulk",
        component: CreateBulk,
      },
    ],
  },
];

export default routes;
