<template>
  <div>
    <h2>Student Union</h2>
    <h4>Coming soon..</h4>
  </div>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style>
</style>