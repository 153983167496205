<template>
  <div>
    <v-dialog v-model="updateDialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-alert prominent outlined type="warning" color="red" border="left">
          <v-row align="center">
            <v-col class="grow">
              Please verify that your details are correct.
              <br>
              <div style="margin-top: 10px; margin-left: -5px">
                <v-btn :disabled="loading" class="ma-1" depressed @click="alreadyUpdated">Yes, it's correct</v-btn>
                <v-btn :disabled="loading" class="ma-1" depressed color="info" v-bind="attrs" v-on="on">
                  No, update info
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-alert>
      </template>

      <v-card>
        <v-card-title class="text-h6 orange darken-3 white--text">
          Update Info
        </v-card-title>

        <v-card-text>
          <v-alert color="orange" border="left" elevation="2" colored-border class="my-5 pl-5">
            Update can only be done once, so please take care to confirm your details.
          </v-alert>

          <v-form class="mt-5" ref="updateForm" @submit.prevent="updateStudentInfo">
            <v-text-field v-model="student_reg_number" class="mb-3" hide-details label="Reg Number"
              placeholder="Enter your registration number" outlined>
            </v-text-field>
            <v-text-field v-model="student_reg_number_confirmation" hide-details label="Confirm Reg Number"
              placeholder="Confirm your registration number" outlined>
            </v-text-field>

            <div class="d-flex mt-3">
              <v-spacer></v-spacer>
              <v-btn large color="primary" type="submit" depressed :loading="loading"
                :disabled="!student_reg_number || (student_reg_number !== student_reg_number_confirmation)">
                Save Update
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="js">
export default {
  props: {
    identity: String,
    reg_number: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      updateDialog: false,
      showUpdatePrompt: true,
      student_reg_number: null,
      student_reg_number_confirmation: null
    };
  },
  watch: {
    student_reg_number(val) { this.student_reg_number = String(val).toUpperCase(); },
    student_reg_number_confirmation(val) { this.student_reg_number_confirmation = String(val).toUpperCase(); }
  },
  methods: {
    alreadyUpdated() {
      if (confirm("Are you sure? Update can only be done once, so please take care to confirm your details.")) {
        this.student_reg_number = this.reg_number;
        this.student_reg_number_confirmation = this.reg_number;

        this.updateStudentInfo();
      }
    },
    async updateStudentInfo() {

      this.loading = true;

      this.$axios
        .put(`/student-info/${this.identity}`, {
          student_reg_number: this.student_reg_number,
          student_reg_number_confirmation: this.student_reg_number_confirmation
        })
        .then((response) => {

          if (response.status === 200) {
            this.$bus.$emit("snackbar", {
              type: "success",
              message: "Student info updated.",
            });

            location.reload();
          }
        })
        .catch((error) => {
          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Failed: " + error.response?.data?.message,
          });
        })
        .finally(() => {
          this.loading = false;
          this.updateDialog = false;
        });

    }
  }
};
</script>
