<template>
  <div class="mb-15">
    <div v-if="profileFound">
      <v-row class="mt-2">
        <v-col sm="12" md="4">
          <v-img class="shrink passport-image mx-auto" cover :src="`${staffIdentity.passport
          ? `${passportBaseURL}${staffIdentity.passport}`
          : '/favicon.png'
          }`" transition="scale-transition" :width="$vuetify.breakpoint.smAndDown ? '230px' : '300px'"
            aspect-ratio="1">
          </v-img>
          <div v-if="staffIdentity.identity">
            <p class="identity mt-3">
              <v-chip outlined small label color="pink" class="white--text">
                {{ formatIdentity(staffIdentity.identity) }}
              </v-chip>
            </p>

            <div class="qrcode">
              <qrcode-vue :value="`${baseURL}${staffIdentity.identity}`"
                :size="$vuetify.breakpoint.smAndDown ? 130 : 180" level="H"></qrcode-vue>
            </div>
          </div>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>
              UNIUYO Staff Information
              <v-spacer></v-spacer>
              <v-chip class="white--text mt-1" :color="statusColor(staffIdentity.status)">
                {{ staffIdentity.status }}
              </v-chip>
              <v-btn color="orange lighten-3" class="text-uppercase ml-2" v-if="staffIdentity.isPrivileged"
                to="/login">Account Login</v-btn>
            </v-card-title>
            <v-tabs dark show-arrows color="red accent-2" v-if="String(staffIdentity.status).toUpperCase() == 'ACTIVE'">
              <v-tab>
                <v-icon left> mdi-account </v-icon>
                Personal
              </v-tab>
              <v-tab v-if="person.role != 'council'">
                <v-icon left> mdi-cash-multiple </v-icon>
                Salary History
              </v-tab>
              <v-tab v-if="person.uu_staff_id == 'PF/10871'">
                <v-icon left> mdi-medical-bag </v-icon>
                Medical
              </v-tab>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Full Name</td>
                          <td>{{ person.full_name }}</td>
                        </tr>
                        <tr>
                          <td>Staff Number</td>
                          <td>{{ person.uu_staff_id }}</td>
                        </tr>
                        <tr v-if="staffIdentity.bloodGroup">
                          <td>Blood Group</td>
                          <td>{{ staffIdentity.bloodGroup }}</td>
                        </tr>
                        <tr>
                          <td>IPPIS ID</td>
                          <td>{{ person.ippis_staff_id }}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>{{ person.email }}</td>
                        </tr>
                        <tr>
                          <td>Phone</td>
                          <td>(+234){{ person.phone }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item v-if="person.role != 'council'">
                <v-card flat>
                  <v-card-text>
                    <Salaries v-if="authenticated" :salaries="salaries" :identity="identity" :person="person" />

                    <div v-else>
                      <v-form v-if="staffIdentity.hasSecurityCode" @submit.prevent="authenticateStaff">
                        <h3 class="mb-3">
                          Please enter your security code to proceed
                        </h3>
                        <v-text-field outlined type="password" label="Security Code"
                          placeholder="Enter your security code" prepend-inner-icon="mdi-lock-question"
                          v-model="securityCode" v-mask="'#####'" hide-details clearable class="mb-2"></v-text-field>
                        <div class="mt-3">
                          <v-btn depressed type="submit" class="mr-1 mb-1" color="green accent-3"
                            :disabled="authenticating">
                            Verify Security Code
                          </v-btn>
                          <v-btn text class="mr-1 mb-1" v-if="person.email" color="lime darken-3"
                            @click="showReset = !showReset">
                            Forgot Security Code
                          </v-btn>
                        </div>

                        <div v-if="showReset">
                          <v-divider class="my-4"></v-divider>
                          <h4 class="mb-3">
                            Your security code will be reset. A new security
                            code will be sent to your email: {{ person.email }}
                          </h4>
                          <v-btn depressed color="orange" @click="resetSecurityCode" :disabled="resetting"
                            :loading="resetting">
                            Yes, Reset Code
                          </v-btn>
                        </div>
                      </v-form>

                      <v-form v-else v-model="registerCodeFormValid" @submit.prevent="registerSecurityCode">
                        <h3 class="mb-3">
                          Please create your security code of
                          <strong>5 numbers</strong>
                          <br />
                          <small>
                            Subsequently, you will access this facility with the
                            code you choose
                          </small>
                        </h3>
                        <v-text-field outlined :rules="required" label="Security Code"
                          placeholder="Enter your security code" prepend-inner-icon="mdi-shield-lock"
                          v-model="securityCode" type="password" v-mask="'#####'" hide-details
                          class="mb-2"></v-text-field>
                        <v-text-field outlined :rules="required" label="Confirm Security Code"
                          placeholder="Enter your security code again" prepend-inner-icon="mdi-shield-lock-outline"
                          v-model="securityCodeConfirm" v-mask="'#####'" type="password" hide-details clearable
                          class="mb-2"></v-text-field>
                        <v-btn depressed type="submit" color="green accent-3" :disabled="
                          !registerCodeFormValid ||
                          securityCode != securityCodeConfirm ||
                          registering
                        " :loading="registering">
                          Register Security Code
                        </v-btn>
                      </v-form>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item v-if="person.uu_staff_id == 'PF/10871'">
                <v-card flat>
                  <v-card-text>
                    <!-- <h2 class="my-5">Medical History</h2> -->

                    <v-card class="mb-5">
                      <v-card-title class="light-green lighten-4 py-2">
                        Allergies
                      </v-card-title>
                      <v-card-text class="py-5">
                        <ul>
                          <li class="mb-3">Dust mites</li>
                          <li class="mb-3">Grass and tree pollen</li>
                          <li>Mould</li>
                        </ul>
                      </v-card-text>
                    </v-card>

                    <v-card class="mb-5">
                      <v-card-title class="red lighten-4 py-2">
                        Family History
                      </v-card-title>
                      <v-card-text class="py-5">
                        <ul>
                          <li :key="history" :class="
                            index + 1 < fakeFamilyHistory.length && 'mb-3'
                          " v-for="(history, index) in fakeFamilyHistory">
                            {{ history }}
                          </li>
                        </ul>
                      </v-card-text>
                    </v-card>

                    <v-card class="mb-5">
                      <v-card-title class="purple lighten-4 py-2">
                        Past Medical History
                      </v-card-title>
                      <v-card-text class="py-5">
                        <v-list-item two-line :key="item.id" v-for="(item, index) in fakeMedicalHistory">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ index + 1 }}. {{ item.name }}
                            </v-list-item-title>
                            <v-spacer></v-spacer>
                            <span class="ml-4 mb-1 grey--text text--darken-1">
                              <small class="text-uppercase blue--text text--darken-3">
                                Treatment &roarr;
                              </small>
                              {{ item.medication }}
                            </span>
                            <v-list-item-subtitle>
                              <small class="ml-4">{{ item.date }}</small>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card-text>
                    </v-card>

                    <!-- <v-card>
                      <v-card-title class="blue lighten-4 py-2">
                        Medications
                      </v-card-title>
                      <v-card-text class="py-5">
                        <v-list-item
                          two-line
                          :key="item.id"
                          v-for="(item, index) in fakeMedications"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ index + 1 }}. {{ item.name }}
                            </v-list-item-title>
                            <v-spacer></v-spacer>
                            <v-list-item-subtitle>
                              <small class="ml-4">{{ item.date }}</small>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card-text>
                    </v-card> -->
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>

          <v-btn link outlined color="orange darken-2" class="my-3">
            Mail Tracking
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-card :color="invalidCard ? 'red' : ''">
        <v-card-text class="text-center">
          <h1 class="my-15">{{ info }}</h1>
        </v-card-text>
      </v-card>
    </div>
    <!-- <div v-if="person.role != 'council'">
      <v-divider class="mt-10"></v-divider>
      <v-row class="py-6">
        <v-col>
          <InformationBoard />
        </v-col>
      </v-row>
    </div> -->
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import Salaries from "./Includes/Salaries";
import { required } from "../../../tools/rules";
import { statusColor } from "../../../tools/specs";

import {
  FAKE_MEDICAL_HISTORY,
  FAKE_FAMILY_HISTORY,
} from "../../../helpers/medical-info";

import { formatDate, formatIdentity } from "../../../tools/format";

export default {
  components: {
    Salaries,
    QrcodeVue,
  },

  props: ["identity"],

  data: () => ({
    loading: false,
    registering: false,
    authenticating: false,
    resetting: false,

    staffIdentity: {
      identity: null,
      hasSecurityCode: false,
    },
    tab: null,

    info: "Verifying ..",
    invalidCard: false,
    showReset: false,

    person: { role: null },
    profileFound: false,

    securityCode: null,
    securityCodeConfirm: null,

    required,
    authenticated: false,
    registerCodeFormValid: false,

    fakeMedicalHistory: FAKE_MEDICAL_HISTORY,
    fakeFamilyHistory: FAKE_FAMILY_HISTORY,

    baseURL: process.env.VUE_APP_STAFF_VERIFY_URL,
    passportBaseURL: process.env.VUE_APP_PASSPORT_URL,
  }),

  methods: {
    formatDate,
    formatIdentity,
    statusColor,
    verifyIdentity() {
      this.info = "Verifying ..";
      this.invalidCard = false;

      this.$axios
        .get(`/identity/staff/${this.identity}`)
        .then(({ data: response }) => {
          const { person } = response;

          this.staffIdentity = response;
          this.person = person;

          this.showReset = false;

          this.profileFound = !!response.identity;
        })
        .catch((error) => {
          const status = error.response.status;

          if (status == 404) {
            this.invalidCard = true;
            this.info = "Invalid card!";
          } else {
            this.info = "Something went wrong... try again.";
          }

          this.profileFound = false;

          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Profile not found",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    authenticateStaff() {
      if (!this.authenticating) {
        this.authenticating = true;

        this.$axios
          .post("/identity/staff/verify-code", {
            identity: this.identity,
            securityCode: this.securityCode,
          })
          .then(({ data: response }) => {
            this.authenticated = true;
            this.salaries = response.salaries;

            this.$bus.$emit("snackbar", {
              type: "success",
              message: "Security code accepted",
            });
          })
          .catch(() => {
            this.$bus.$emit("snackbar", {
              type: "error",
              message: "Wrong security code",
            });
          })
          .finally(() => {
            this.authenticating = false;
          });
      }
    },
    resetSecurityCode() {
      if (!this.resetting) {
        this.resetting = true;

        this.$axios
          .get(`/identity/staff/reset-code/${this.identity}`)
          .then(() => {
            this.verifyIdentity();

            this.$bus.$emit("snackbar", {
              type: "success",
              message: "Security code has been reset. Check your email",
            });
          })
          .catch(() => {
            this.$bus.$emit("snackbar", {
              type: "error",
              message: "Could not reset security code",
            });
          })
          .finally(() => {
            this.resetting = false;
          });
      }
    },
    registerSecurityCode() {
      if (!this.registering) {
        this.registering = true;

        this.$axios
          .post("/identity/staff/register-code", {
            identity: this.identity,
            securityCode: this.securityCode,
          })
          .then(() => {
            this.verifyIdentity();
            this.$bus.$emit("snackbar", {
              type: "success",
              message: "Security code registered",
            });
          })
          .catch(() => {
            this.$bus.$emit("snackbar", {
              type: "error",
              message: "Could not register security code",
            });
          })
          .finally(() => {
            this.registering = false;
          });
      }
    },
  },

  mounted() {
    this.verifyIdentity();
  },
};
</script>
