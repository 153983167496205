<template>
  <v-expansion-panels accordion>
    <v-expansion-panel
      :key="index"
      :salary="salary"
      v-for="(salary, index) in salaries"
    >
      <v-expansion-panel-header>
        <h5>{{ salary.month }}</h5>
        <v-spacer></v-spacer>
      </v-expansion-panel-header>
      <v-expansion-panel-content color="red lighten-5 pt-4">
        <h4>
          {{ salary.department }}<br />
          <small>
            {{ salary.job_title }} &dash; {{ salary.grade }}, STEP
            {{ salary.grade_step }}
          </small>
        </h4>
        <h3 class="mt-2 mb-3">
          <small> NET PAY:</small>
          <span class="blue--text">
            {{ formatCurrency(salary.net_pay) }}
          </span>
        </h3>
        <v-btn
          small
          depressed
          class="white--text"
          color="green accent-4"
          :disabled="downloading"
          @click="downloadPaySlip(salary)"
        >
          Download {{ salary.month }} Pay-slip
          <v-icon small right>mdi-download-outline</v-icon>
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { formatCurrency } from "../../../../tools/format";

export default {
  props: {
    salaries: {
      type: Array,
      default: () => [],
    },
    person: {
      type: Object,
      default: () => {},
    },
    identity: {
      type: String,
    },
  },

  data: () => ({
    downloading: false,
  }),

  methods: {
    formatCurrency,
    downloadPaySlip(salary) {
      if (!this.downloading) {
        const { id } = salary;
        this.downloading = true;

        this.$axios({
          url: `/payslip/${this.identity}/${id}`,
          method: "GET",
          responseType: "blob",
        })
          .then((response) => {
            this.download_salary = "";
            this.downloading = false;

            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              `${this.person.full_name} ${
                this.person.uu_staff_id
                  ? "(" + this.person.uu_staff_id + ")"
                  : ""
              } - Payslip for ${salary.month}.pdf`
            );
            document.body.appendChild(fileLink);
            fileLink.click();

            this.$bus.$emit("snackbar", {
              type: "success",
              message: "Payslip downloaded",
            });
          })
          .catch(() => {
            // this.download_salary = "";
            this.downloading = false;
            this.$bus.$emit("snackbar", {
              type: "error",
              message: "Could not download payslip",
            });
          });
      }
    },
  },
};
</script>

<style>
</style>