const required = [(value) => !!value || "This is required"];

const attachment = [
  (value) =>
    !value ||
    value.size < 500000 ||
    "Attachment size should be less than 500 KB!",
];

const ticket = [
  (value) => !value || value.length == 7 || "This is a 6 digit code",
];

const email = [(value) => /.+@.+\..+/.test(value) || "Must be a valid email"];

const phone = [
  (value) =>
    (value && value.length == 13) || "Must be a valid mobile phone number",
];

const statement = [
  (value) => !value || value.length <= 300 || "Must not exceed 300 characters",
];

export { required, attachment, ticket, email, phone, statement };
