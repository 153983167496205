<template>
  <div>
    <h2>Create Student Identity</h2>
    <v-card flat max-width="420px">
      <v-form
        class="mt-5"
        v-model="findStudentFormValid"
        @submit.prevent="findStudent"
      >
        <v-text-field
          outlined
          clearable
          hide-details
          class="identification"
          v-model="registrationNumber"
          prepend-inner-icon="mdi-school"
          label="Matriculation Number *"
        ></v-text-field>

        <v-btn
          large
          :loading="loading"
          :disabled="loading"
          class="mt-3"
          type="submit"
          color="primary"
          outlined
        >
          Find Student <v-icon right>mdi-account-search-outline</v-icon>
          <template v-slot:loader>
            <span>Finding...</span>
          </template>
        </v-btn>
      </v-form>
    </v-card>

    <div v-if="studentFound.id > 0">
      <v-divider class="mt-7 mb-5"></v-divider>

      <h2>Student Details</h2>

      <v-row class="mt-2">
        <v-col sm="12" md="4">
          <!-- <v-img
            class="shrink passport-image mx-auto"
            cover
            :src="`${
              studentFound.passport
                ? `https://uniuyo.edu.ng/eportals/passports/${studentFound.passport}`
                : '/favicon.png'
            }`"
            :width="$vuetify.breakpoint.smAndDown ? '230px' : ''"
            transition="scale-transition"
            aspect-ratio="1"
          >
          </v-img> -->
          <v-card>
            <v-card-text>
              <v-img
                cover
                class="shrink passport-image mx-auto"
                :src="`${
                  studentFound.passport
                    ? `https://uniuyo.edu.ng/eportals/passports/${studentFound.passport}`
                    : '/favicon.png'
                }`"
                :width="$vuetify.breakpoint.smAndDown ? '230px' : ''"
                transition="scale-transition"
                aspect-ratio="1"
              >
              </v-img>
            </v-card-text>
          </v-card>
          <div v-if="studentFound.identity">
            <p class="identity mt-3 mb-7">
              <v-chip outlined small label color="pink" class="white--text">
                {{ formatIdentity(studentFound.identity.uid) }}
              </v-chip>
            </p>

            <div class="qrcode">
              <qrcode-vue
                :value="`${baseURL}${studentFound.identity.uid}`"
                :size="$vuetify.breakpoint.smAndDown ? 100 : 180"
                level="H"
              ></qrcode-vue>
            </div>

            <p class="text-center">
              <v-btn text :to="`/verify/${studentFound.identity.uid}`">
                Verify
              </v-btn>
            </p>
          </div>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>
              Student Information
              <v-spacer></v-spacer>
              <div v-if="studentFound.identity">
                <v-chip
                  class="white--text text-uppercase"
                  v-if="studentFound.identity.status"
                  :color="statusColor(studentFound.identity.status)"
                >
                  {{ studentFound.identity.status }}
                </v-chip>
                <v-btn
                  v-if="
                    String(studentFound.identity.status).toUpperCase() !=
                    'ACTIVE'
                  "
                  :disabled="reissuing"
                  @click="issueNewCard"
                  :loading="reissuing"
                  class="ml-2"
                  color="lime"
                  small
                >
                  Issue New Card
                </v-btn>
              </div>
            </v-card-title>
            <v-tabs dark show-arrows color="green lighten-2">
              <v-tab>
                <v-icon left> mdi-account </v-icon>
                Personal
              </v-tab>
              <v-tab>
                <v-icon left> mdi-school </v-icon>
                Academic
              </v-tab>
              <v-tab>
                <v-icon left> mdi-bookshelf </v-icon>
                Courses
              </v-tab>
              <v-tab v-if="isAdmin">
                <v-icon left> mdi-check-decagram-outline </v-icon>
                Results
              </v-tab>
              <!-- <v-tab>
                <v-icon left> mdi-receipt </v-icon>
                Dues
              </v-tab> -->

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Full Name</td>
                          <td>{{ studentFound.name }}</td>
                        </tr>
                        <tr>
                          <td>Date of Birth</td>
                          <td>{{ studentFound.dob }}</td>
                        </tr>
                        <tr>
                          <td>Gender</td>
                          <td>{{ studentFound.gender }}</td>
                        </tr>
                        <!-- <tr>
                          <td>Blood Group</td>
                          <td>{{ studentFound.bloodGroup }}</td>
                        </tr> -->
                        <tr>
                          <td>Email</td>
                          <td>{{ studentFound.email }}</td>
                        </tr>
                        <tr>
                          <td>Phone</td>
                          <td>{{ studentFound.phone }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Matriculation Number</td>
                          <td>{{ studentFound.matric }}</td>
                        </tr>

                        <tr>
                          <td>Course of Study</td>
                          <td>{{ studentFound.course }}</td>
                        </tr>

                        <tr>
                          <td>Department</td>
                          <td>{{ studentFound.department }}</td>
                        </tr>

                        <tr>
                          <td>Faculty</td>
                          <td>{{ studentFound.faculty }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <div
                      v-if="
                        studentFound.lastRegistration.coursesRegistered &&
                        studentFound.lastRegistration.coursesRegistered[
                          'length'
                        ] > 0
                      "
                    >
                      <div style="text-align: right">
                        <h3>
                          Registration for
                          {{ studentFound.lastRegistration.sessionRegistered }}
                          {{
                            studentFound.lastRegistration.semesterRegistered
                          }} Semester
                        </h3>
                        <p>
                          Date Registered:
                          {{ studentFound.lastRegistration.dateRegistered }}
                        </p>
                      </div>
                      <table class="table">
                        <tbody>
                          <tr>
                            <th>Course Code</th>
                            <th>Course Title</th>
                            <th>Credit Hours</th>
                          </tr>
                          <tr
                            v-for="course in studentFound.lastRegistration
                              .coursesRegistered"
                            :key="course.CourseID"
                          >
                            <td>{{ course.CourseCode }}</td>
                            <td style="text-align: left">
                              {{ course.CourseTitle }}
                            </td>
                            <td>{{ course.CH }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p class="my-5 overline red--text" v-else>
                      No registration for this semester!
                    </p>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-btn
                      depressed
                      color="lime"
                      class="my-3"
                      @click="fetchResults"
                      v-if="!resultsFetched"
                      :loading="fetchingResults"
                      :disabled="fetchingResults"
                    >
                      Load results
                      <v-icon right>mdi-cloud-sync</v-icon>
                    </v-btn>
                    <div v-if="resultsFetched">
                      <section v-if="studentResults.length > 0">
                        <h4>
                          <strong class="orange--text text--darken-2"
                            >NOTE:
                          </strong>
                          For complaints and enquiries, please contact your
                          examination officer
                        </h4>
                        <v-divider class="mt-3 mb-4"></v-divider>
                        <StudentResults :results="studentResults" />
                      </section>
                      <h3 class="my-5" v-else>No results found</h3>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <p class="my-5 overline">
                      <strong>Coming soon</strong>
                    </p>
                  </v-card-text>
                </v-card>
              </v-tab-item> -->
            </v-tabs>
          </v-card>

          <div v-if="!studentFound.identity" class="mt-5">
            <v-btn
              large
              color="green"
              :loading="creating"
              :disabled="creating"
              @click="createProfile"
            >
              Create identity <v-icon right>mdi-shield-link-variant</v-icon>
              <template v-slot:loader>
                <span>Creating...</span>
              </template>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { required } from "../../../tools/rules";
import { statusColor } from "../../../tools/specs";
import { formatIdentity, formatDate } from "../../../tools/format";
import QrcodeVue from "qrcode.vue";
import StudentResults from "./Includes/StudentResults";

export default {
  components: {
    QrcodeVue,
    StudentResults,
  },

  data: () => ({
    rules: {
      required,
    },
    loading: false,
    studentFound: {
      id: 0,
      matric: null,
      lastRegistration: { coursesRegistered: [] },
    },
    registrationNumber: null,
    findStudentFormValid: false,

    tab: null,

    creating: false,
    reissuing: false,
    librarySubscriptions: [],

    resultsFetched: false,
    fetchingResults: false,
    studentResults: [],

    baseURL: process.env.VUE_APP_VERIFY_URL,
  }),

  computed: {
    isAdmin() {
      const { type } = JSON.parse(localStorage.getItem("idmsUser"));
      return type.toLowerCase() == "admin";
    },
  },

  methods: {
    formatDate,
    formatIdentity,
    statusColor,
    findStudent() {
      if (!this.registrationNumber) return;

      this.loading = true;


      this.$axios
        .get("/student", { params: { registration: this.registrationNumber } })
        .then(({ data: response }) => {
          this.studentFound = response;
          if (response.identity) {
            const { subscriptions } = response.identity;

            this.librarySubscriptions = [...(subscriptions || [])].filter(
              (s) => (s.type = "library")
            );
          }

          this.studentResults = [];
          this.resultsFetched = false;
        })
        .catch(() => {
          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Student not found",
          });
        })
        .finally(() => {
          this.loading = false;
          // this.registrationNumber = null;
        });
    },
    createProfile() {
      if (!this.studentFound.id) return;

      if (!this.creating) {
        this.creating = true;
        this.$axios
          .post("/identity", { studentId: this.studentFound.id })
          .then(() => {
            this.findStudent();
          })
          .catch(() => {
            this.$bus.$emit("snackbar", {
              type: "error",
              message: "Could not create identity",
            });
          })
          .finally(() => {
            this.creating = false;
          });
      }
    },
    issueNewCard() {
      if (!this.reissuing) {
        this.reissuing = true;
        this.$axios
          .post("/identity/reissue", { studentId: this.studentFound.id })
          .then(() => {
            this.findStudent();
          })
          .catch(() => {
            this.$bus.$emit("snackbar", {
              type: "error",
              message: "Could not create identity",
            });
          })
          .finally(() => {
            this.reissuing = false;
          });
      }
    },
    fetchResults() {
      if (!this.fetchingResults) {
        this.fetchingResults = true;

        this.$axios
          .get(`/admin/results/${this.studentFound.id}`)
          .then(({ data: response }) => {
            this.studentResults = response;
            this.resultsFetched = !!response;
          })
          .catch(() => {
            this.$bus.$emit("snackbar", {
              type: "error",
              message: "Could not load results",
            });
          })
          .finally(() => {
            this.fetchingResults = false;
          });
      }
    },
  },
};
</script>
