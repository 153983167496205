import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

// So that we can navigate to the same route without errors
// Should we be doing this? Maybe not.
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {});
};

import AuthRoutes from "../routes/auth";
import StaffRoutes from "../routes/staff";
import LibraryRoutes from "../routes/library";
import StudentIdentityRoutes from "../routes/identity.student";
import StaffIdentityRoutes from "../routes/identity.staff";
import StudentUnionRoutes from "../routes/sug";
import SearchRoutes from "../routes/search";
// import NotificationRoutes from "../routes/notification";
import Home from "../components/Home";
import VerifyStudentIdentity from "../components/Identity/Students/Verify";
import VerifyStaffIdentity from "../components/Identity/Staff/Verify";

const router = new VueRouter({
  mode: "history",
  routes: [
    ...AuthRoutes,
    ...StaffRoutes,
    ...LibraryRoutes,
    ...SearchRoutes,
    ...StudentIdentityRoutes,
    ...StaffIdentityRoutes,
    ...StudentUnionRoutes,
    // {
    //   path: "/dashboard",
    //   name: "dashboard",
    //   component: Dashboard,
    //   meta: { requiresAdmin: true },
    // },
    // {
    //   props: true,
    //   path: "/examinations/:id",
    //   name: "examination",
    //   component: Examination,
    //   meta: { requiresAuth: true },
    // },
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        // requiresVisitor: true,
        description: "Home page",
      },
    },
    {
      props: true,
      name: "verifyStudent",
      path: "/verify/:identity",
      component: VerifyStudentIdentity,
      meta: {
        // requiresVisitor: true,
        description: "Verify student identity card",
      },
    },
    {
      props: true,
      name: "verifyStaff",
      path: "/verify/staff/:identity",
      component: VerifyStaffIdentity,
      meta: {
        // requiresVisitor: true,
        description: "Verify staff identity card",
      },
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (!localStorage.getItem("idmsAuth")) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      const { type } = JSON.parse(localStorage.getItem("idmsUser"));
      if (type == "admin" || type == "root") {
        next();
      } else if (type == "Examiner") {
        next({
          path: "questions",
        });
      } else {
        next({
          path: "/my-applications",
        });
      }
    }
  } else {
    next();
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!localStorage.getItem("idmsAuth")) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }

  // Kept only for reference
  // if (to.matched.some((record) => record.meta.requiresTeacherOrMentor)) {
  //   const { type } = JSON.parse(localStorage.getItem("idmsUser"));
  //   if (type == "Teacher" || type == "Mentor") {
  //     next();
  //   } else {
  //     next({
  //       path: "/my-applications",
  //     });
  //   }
  // } else {
  //   next();
  // }

  if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (localStorage.getItem("idmsAuth")) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
