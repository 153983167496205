<template>
  <div>
    <h2 class="mt-3 mb-8 d-flex">
      Staff Identity Profiles ({{ profiles.length }} / {{ count }})
      <span class="grey--text" v-if="activeQuery">&nbsp;&mdash; Filtered</span>
      <v-spacer></v-spacer>
      <span v-if="count && $vuetify.breakpoint.mdAndUp">
        <v-btn
          depressed
          class="ma-1"
          color="blue lighten-5"
          @click="expandAll = !expandAll"
        >
          {{ expandAll ? "Collapse" : "Expand" }} All
        </v-btn>
        <v-btn
          depressed
          class="ma-1"
          color="teal lighten-5"
          @click="filterSort = !filterSort"
        >
          Filter / Sort
        </v-btn>
      </span>
    </h2>

    <v-row>
      <v-col :lg="filterSort ? 8 : 12">
        <div v-if="profiles.length > 0">
          <ProfileCard
            v-for="profile in profiles"
            :admin="isAdmin"
            :key="profile.identity"
            :profile="profile"
            :expanded="expandAll"
          />
          <div class="my-5">
            <v-btn
              text
              block
              large
              color="green"
              @click.prevent="loadMoreCards"
              :disabled="this.profiles.length == count || reloading"
            >
              {{ this.profiles.length == count ? "All done!" : "Load more" }}
            </v-btn>
          </div>
        </div>
        <v-card v-else>
          <v-card-text class="py-5">
            <h2>No identity found.</h2>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col lg="4" v-if="filterSort">
        <v-card color="teal lighten-5">
          <v-card-title> Filter / Sort </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form
              ref="filterSortForm"
              name="filterSortForm"
              @submit.prevent="fetchProfiles(false, true)"
            >
              <v-text-field
                outlined
                clearable
                hide-details
                label="Staff Number"
                class="mb-3 identification"
                v-model="staffNumber"
                prepend-inner-icon="mdi-card-account-details-outline"
              ></v-text-field>
              <v-select
                outlined
                hide-details
                clearable
                v-model="cardStatus"
                :items="identityStatuses"
                label="Card Status"
                class="mb-3"
                prepend-inner-icon="mdi-label-outline"
              >
              </v-select>
              <v-text-field
                outlined
                clearable
                type="date"
                hide-details
                class="mb-3"
                label="Created from"
                v-model="createdFrom"
                prepend-inner-icon="mdi-calendar-start"
              ></v-text-field>
              <v-text-field
                outlined
                clearable
                type="date"
                hide-details
                class="mb-3"
                label="Created to"
                v-model="createdTo"
                prepend-inner-icon="mdi-calendar-end"
              ></v-text-field>
              <v-btn
                depressed
                type="submit"
                color="teal accent-3"
                class="mr-1 mb-1"
                :disabled="!filteringProfiles"
              >
                Apply Filter
              </v-btn>
              <v-btn
                depressed
                color="grey lighten-3"
                @click="clearFilterForm"
                class="mr-1 mb-1"
              >
                Clear Filter
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import ProfileCard from "./Includes/ProfileCard";
import { identityStatuses } from "../../../tools/specs";

export default {
  components: { ProfileCard },

  data: () => ({
    count: 0,
    profiles: [],

    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    createdToMenu: false,

    staffNumber: null,
    cardStatus: null,
    createdFrom: null,
    createdTo: null,

    activeQuery: false,

    expandAll: false,
    filterSort: false,

    loading: false,
    reloading: false,

    identityStatuses,
  }),

  computed: {
    isAdmin() {
      const { type } = JSON.parse(localStorage.getItem("idmsUser"));
      return type.toLowerCase() == "admin";
    },
    filteringProfiles() {
      return (
        this.staffNumber ||
        this.cardStatus ||
        this.createdFrom ||
        this.createdTo
      );
    },
  },

  methods: {
    cleanStaffNumber(number) {
      return String(number).trim().toUpperCase().split("UU/").pop();
    },
    clearFilterForm() {
      this.staffNumber = null;
      this.cardStatus = null;
      this.createdFrom = null;
      this.createdTo = null;

      this.activeQuery = false;

      this.refetchIdentities();
    },
    composeQuery(loadMoreByQuery = false) {
      let grinder = `
        ${loadMoreByQuery ? `skip=${this.profiles.length}&` : "skip=0&"}
        ${
          this.staffNumber
            ? `staff=${this.cleanStaffNumber(this.staffNumber)}&`
            : ""
        }
        ${this.cardStatus ? `status=${this.cardStatus}&` : ""}
        ${this.createdFrom ? `start_date=${this.createdFrom}&` : ""}
        ${this.createdTo ? `end_date=${this.createdTo}&` : ""}
      `
        .slice(0, -1)
        .replace(/\s+/g, "");

      return String(grinder).slice(0, -1).replace(/\s+/g, "");
    },
    fetchProfiles(loadMore = false, filtering = false) {
      this.loading = !loadMore;
      this.reloading = loadMore;
      let query;

      if (filtering) {
        this.activeQuery = true;
        query = this.composeQuery();
      } else if (loadMore && this.activeQuery) {
        query = this.composeQuery(true);
      } else {
        query = `skip=${this.profiles.length}`;
      }

      this.$axios
        .get(`/identities/staff?${query}`)
        .then(async ({ data: response }) => {
          const { identities, count } = response;
          if (filtering) {
            this.profiles = identities;
          } else {
            this.profiles = [...this.profiles, ...identities];
          }
          this.count = count;
        })
        .finally(() => {
          this.loading = false;
          this.reloading = false;
        });
    },
    loadMoreCards() {
      this.fetchProfiles(true);
    },
    refetchIdentities() {
      this.profiles = [];
      this.fetchProfiles();
    },
  },

  mounted() {
    this.fetchProfiles();

    this.$bus.$on("identities.reload", this.refetchIdentities);
    this.$bus.$on("identity.deleted", this.refetchIdentities);
  },
};
</script>

<style>
</style>