<template>
  <v-row>
    <v-col>
      <v-card max-width="420px" class="login-wrapper mx-auto">
        <h3
          class="
            py-3
            text-center
            green
            lighten-3
            green--text
            text--darken-4 text-uppercase
          "
        >
          Log-in
        </h3>
        <v-card class="px-5 py-8" color="basil" flat>
          <v-form
            class="mr-2 mt-4"
            v-model="valid"
            @submit.prevent="authenticate"
          >
            <v-text-field
              outlined
              label="Staff Number *"
              prepend-icon="mdi-card-account-details-outline"
              :rules="rules.required"
              v-model="staffNumber"
              prefix="PF/"
              type="number"
              min="0001"
            ></v-text-field>

            <v-text-field
              outlined
              label="Password *"
              prepend-icon="mdi-lock-outline"
              :rules="rules.required"
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            ></v-text-field>

            <div class="ml-8">
              <p class="mb-7">* mark required fields.</p>
              <v-btn
                color="green"
                type="submit"
                class="white--text mr-2 my-2"
                :disabled="!valid || loading"
                :loading="loading"
              >
                Login
              </v-btn>
              <v-btn color="red darken-3 my-2" text to="/"> Homepage </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { required } from "../../tools/rules";

export default {
  data: () => ({
    valid: false,
    showPassword: false,
    rules: {
      required,
    },

    staffNumber: null,
    password: null,
    loading: false,
  }),

  watch: {
    staffNumber(val) {
      if (val) this.staffNumber = val.toUpperCase();
    },
  },
  methods: {
    authenticate() {
      this.loading = true;
      this.$axios
        .post("/login", {
          staffNumber: "PF/" + this.staffNumber,
          password: this.password,
        })
        .then(({ data: response }) => {
          const { token, user } = response;
          localStorage.setItem("idmsUser", JSON.stringify(user));
          localStorage.setItem("idmsToken", token);
          localStorage.setItem("idmsAuth", "true");

          this.$bus.$emit("snackbar", {
            type: "success",
            message: "Login successful",
          });

          this.$bus.$emit("login");

          let searchParams = new URLSearchParams(window.location.search);

          if (searchParams.has("redirect")) {
            this.$router.push({ path: `${searchParams.get("redirect")}` });
          } else if (user.type == "library") {
            this.$router.push({ name: "library" });
          } else {
            this.$router.push({ name: "home" });
          }

          // if (searchParams.has("redirect")) {
          //   this.$router.push({ path: `${searchParams.get("redirect")}` });
          // } else if (user.type == "Administrator" || user.type == "Root") {
          //   this.$router.push({ name: "dashboard" });
          // } else if (user.type == "Examiner") {
          //   this.$router.push({ name: "questions" });
          // } else {
          //   this.$router.push({ name: "my-applications" });
          // }
        })
        .catch((error) => {
          console.log(error);
          this.$bus.$emit("snackbar", {
            type: "error",
            message: "Bad credentials",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-polygon {
  background: #2a8414;
  min-height: 100vh;
  background-image: url("/images/polygon.svg");
  background-position: center;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    color: #ffffff;
    font-size: 2.5em;
    text-transform: uppercase;
    text-align: center;

    small {
      color: #ffffff;
      display: block;
      margin-top: 1em;
    }
  }
}

.container {
  z-index: 2;
  position: relative;
  margin: 10vh auto auto;
}

.login-wrapper {
  margin: 5% auto 0;
}

@media screen and (max-width: 480px) {
  .container {
    margin: 5% auto auto;
  }
}
</style>